import React, { FC, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import { usePagination } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { Button } from '../../Buttons';
import { IPaginationProps } from './PaginationProps';
import styles from './Pagination.module.css';

const cx = classNames.bind(styles);

/**
 * The Pagination component depends on the PaginationProvider from the universal-components package.
 * Make sure it is loaded before using this component!
 */
export const Pagination: FC<IPaginationProps> = ({
  totalPages = 1,
  labels = {},
  limits = [25, 50, 75],
  totalResults,
}) => {
  const {
    page,
    limit,
    setPage,
    setLimit,
  } = usePagination();

  const itemsPerPageLabel = labels.itemsPerPage || 'Results per page';

  const updateLimit = useCallback((newLimit: number) => {
    setPage(1);
    setLimit(newLimit);
  }, [setPage, setLimit]);

  return (
    <div className={cx(styles.pagination)}>
      <div className={cx(styles.limits)}>
        {`${itemsPerPageLabel}:`}
        <ul className={cx(styles['limit-options'])}>
          {limits.map((clickableLimit) => (
            <li key={clickableLimit} className={limit === clickableLimit ? styles.active : ''}>
              <Button link onClick={() => updateLimit(clickableLimit)}>
                {clickableLimit}
              </Button>
            </li>
          ))}
        </ul>
        {totalResults && <span className={cx(styles['total-results'])}>{totalResults}</span>}
      </div>
      <div className={cx(styles.pages)}>
        {totalPages > 1 && (
          <ReactPaginate
            forcePage={(page || 1) - 1}
            breakLabel="…"
            nextLabel={(
              <svg height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
              </svg>
            )}
            previousLabel={(
              <svg height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
              </svg>
            )}
            containerClassName={cx(styles['pagination-list'])}
            pageClassName={cx(styles['pagination-item'])}
            breakClassName={cx(styles['pagination-item'])}
            previousClassName={cx(styles['pagination-item'])}
            nextClassName={cx(styles['pagination-item'])}
            activeClassName={cx(styles.selected)}
            disabledClassName={cx(styles.disabled)}
            pageLinkClassName={cx(styles.pagelink)}
            breakLinkClassName={cx(styles.pagelink)}
            previousLinkClassName={cx(styles.pagelink)}
            nextLinkClassName={cx(styles.pagelink)}
            activeLinkClassName={cx(styles['active-pagelink'])}
            onPageChange={(e) => setPage(e.selected + 1)}
            pageRangeDisplayed={2}
            pageCount={totalPages || 1}
            marginPagesDisplayed={2}
          />
        )}
      </div>
    </div>
  );
};
