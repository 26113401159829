import React, {
  PropsWithChildren, useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames/bind';
import { useOnClickOutside, useSyncRefs } from '@brainstud/universal-components';
import { Panel } from '../../Static';
import { FloatingPanelButtonProps } from './FloatingPanelButtonProps';
import styles from './FloatingPanelButton.module.css';

const cx = classNames.bind(styles);

/**
 * Shows a simple button which opens a Panel that floats. Typically used to show filtering options.
 */
export const FloatingPanelButton = React.forwardRef<HTMLButtonElement, PropsWithChildren<FloatingPanelButtonProps>>((
  {
    button,
    open,
    defaultOpen,
    style,
    className,
    children,
  }, ref,
) => {
  // Logic for handling opening / closing of panel
  const [openPanel, setOpenPanel] = useState(defaultOpen);

  const panel = useRef<HTMLDivElement>(null);
  const buttonRef = useSyncRefs(ref, null);

  useOnClickOutside([panel, buttonRef], () => {
    setOpenPanel(false);
  });

  const togglePanel = useCallback(() => {
    setOpenPanel((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (open !== undefined) {
      setOpenPanel(open);
    }
  }, [open]);

  return (
    <div className={cx(styles.root, className)} style={style}>
      <button
        type="button"
        ref={buttonRef}
        className={cx(styles.button, { active: openPanel })}
        onClick={togglePanel}
      >
        {button}
      </button>
      {openPanel && (
        <div ref={panel} className={cx(styles.base)}>
          <Panel className={cx(styles.panel)}>
            {children}
          </Panel>
        </div>
      )}
    </div>
  );
});
