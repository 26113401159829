import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import styles from './ErrorModal.module.css';
import { useTranslator } from '../../../Providers/Translator';
import { IException } from '../../../Errors/Exception';

const cx = classNames.bind(styles);

type TProps = {
  error: IException;
  content: ReactNode | string;
};

/**
 * Shows a bare modal with the error details
 */
export const ErrorModal = ({ error, content }: TProps) => {
  const { name } = error;
  const type = (error.type && error.type.toLowerCase()) || 'error';

  const TranslationContext = useTranslator();

  const t = TranslationContext
    ? TranslationContext[0]
    : (input, data, fallback = '') => fallback;

  const title = TranslationContext
    ? t(`${type}s.${name}.title`, {}, t(`${type}s.default.title`))
    : 'An error occurred.';

  return content ? <React.Fragment>{content}</React.Fragment> : (
    <div className={cx('base')}>
      <div className={cx('modal')}>
        <h1>{title}</h1>
        <p>
          {t(`${type}s.${name}.description`, {}, t('errors.default.description', {}, 'A report of this error has been sent to us already and we will do our best to fix the issue. Our apologies for the inconvenience.'))}
        </p>

        {!error.isCustomException
         && (
         <p className={cx('error-msg')}>
           {t(`${type}s.${name}.details`, {}, t(`${type}s.default.details`, {}, 'Technical details:'))}
           {' '}
           <code>{error.message}</code>
         </p>
         )}

        {error?.actions ? Object.keys(error.actions).map((key) => (
          <button type="button" key={key} onClick={error?.actions?.[key]}>
            {t(`${type}s.${name}.actions.${key}`, {}, t(`${type}s.default.actions.${key}`, {}, t(key)))}
          </button>
        ))
          : (
            <a href="/">
              {t(`${type}s.${name}.actions.go_home`, {}, t(`${type}s.default.actions.go_home`, {}, 'Go to homepage'))}
            </a>
          )}
      </div>
    </div>
  );
};
