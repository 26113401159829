import React, { ComponentProps, createContext, Key } from 'react';
import type { FlowStep } from './FlowStep';

export type TFlowStepChild = (React.ReactElement<ComponentProps<typeof FlowStep>> & { key: null | Key, type: { displayName: 'FlowStep' } });

export interface IFlowContext {
  trace: number[],
  labels: {
    [key: string]: string;
  },
  step: number,
  total: number,
  steps: TFlowStepChild[],
  set: (values: Partial<IFlowContext>) => void;
  goto: (step: number) => void;
  next: () => void,
  previous: () => void;
  defaults: {
    next: IFlowContext['next'],
    previous: IFlowContext['previous'],
    labels: IFlowContext['labels'],
  }
}

export const FlowContext = createContext<null | IFlowContext>(null);
