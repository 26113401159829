import React, { PropsWithChildren, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './UploadButton.module.css';
import { UploadButtonProps } from './UploadButtonProps';
import { UploadBox } from '../UploadBox';
import { Button } from '../../Buttons';
import { Indicator } from '../../Loaders';

const cx = classNames.bind(styles);

export const UploadButton = ({
  url, expand, quiet, small, outline, children, className, style,
  autoProcessQueue = true, onBeforeUpload, onAfterUpload, onUploadError, ...props
}: PropsWithChildren<UploadButtonProps>) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleBeforeUpload = useCallback((...args) => {
    setLoading(true);
    return new Promise<void>(() => onBeforeUpload?.(...args));
  }, [onBeforeUpload]);
  const handleAfterUpload = useCallback((...args) => {
    setLoading(false);
    setSuccess(true);
    return new Promise<void>(() => onAfterUpload?.(...args));
  }, [onAfterUpload]);
  const handleUploadError = useCallback((a, b) => {
    setLoading(false);
    setError(true);
    return new Promise<void>(() => onUploadError?.(a, b));
  }, [onUploadError]);

  const content = children || (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
    </svg>
  );

  return (
    <UploadBox
      {...props}
      label=""
      url={url}
      preview={false}
      expand={expand}
      quiet
      style={style}
      className={cx(className)}
      onFileUpload={handleBeforeUpload}
      autoProcessQueue={autoProcessQueue}
      onAfterUpload={handleAfterUpload}
      onUploadError={handleUploadError}
    >
      <Button type="button" small={small} quiet={quiet} outline={outline}>
        {!loading && !error && !success ? content : (
          <Indicator
            loading={loading}
            success={success}
            error={error}
          />
        )}
      </Button>
    </UploadBox>
  );
};
