import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { TEmitFn, TEventHandlerFn, TSubscribeFn } from './EventHandlerTypes';

type TEvent = {
  name: string;
  data: Array<any>
};

type TSubscriptions = {
  [key: string]: Array<TEventHandlerFn>;
};

/**
 * Creates a custom event handling system in Javascript to execute a set of handlers
 * based on custom subscribed events
 */
export function useEventHandler<TEventNames extends string = string>() {
  const [subscriptions, setSubscription] = useState<TSubscriptions>({});
  const [events, setEvents] = useState<TEvent[]>([]);

  const subscribe = useCallback<TSubscribeFn<TEventNames>>((event, handler) => {
    if (typeof handler !== 'function') return () => null;
    setSubscription((prevSubscriptions) => ({
      ...prevSubscriptions,
      [event]: [...(prevSubscriptions[event] || []), handler],
    }));
    return () => {
      setSubscription((prevSubscriptions) => ({
        ...prevSubscriptions,
        [event]: prevSubscriptions[event].filter((anyHandler) => anyHandler !== handler),
      }));
    };
  }, []);

  const emit = useCallback<TEmitFn>((name, ...data) => {
    setEvents((prevEvents) => [...prevEvents, { name, data }]);
  }, []);

  useEffect(() => {
    while (events.length > 0) {
      const event = events.shift();
      if (event && subscriptions[event.name]) {
        subscriptions[event.name].forEach((handler) => handler(...event.data));
      }
    }
  }, [events, subscriptions]);

  return useMemo(() => ({
    subscribe,
    emit,
  }), [subscribe, emit]);
}
