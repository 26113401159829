import React, { CSSProperties, PropsWithChildren, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from '../Table.module.css';
import { useTableProvider } from '../Context/useTableProvider';

const classes = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * Renders a table heading
 */
export const THead = ({ className, style, children }: PropsWithChildren<Props>) => {
  const { dispatch } = useTableProvider();

  useEffect(() => {
    dispatch({ type: 'SET_HEADER', payload: true });
    return () => {
      dispatch({ type: 'SET_HEADER', payload: false });
    };
  },
  [dispatch]);

  return (
    <thead className={classes(styles.header, className)} style={style}>
      {children}
    </thead>
  );
};
