import React from 'react';
import classNames from 'classnames/bind';
import { withDraggability } from '@brainstud/universal-components';
import styles from './DraggableArea.module.css';
import { DraggableAreaProps } from './DraggableAreaProps';

const cx = classNames.bind(styles);

/**
 * An area that can be dragged to a droparea
 */
export const DraggableArea = withDraggability<DraggableAreaProps>(({
  element, isDragging, isDropped, isDraggable, isPreview, dropArea, children, validity, className, style,
}) => (
  <div
    ref={element}
    style={style}
    className={cx(styles.base, 'draggable-area', {
      'is-dragging': isDragging,
      'is-draggable': isDraggable,
      'is-fixed': !isDraggable,
      'is-listed': isDropped || validity !== undefined,
      'is-drag-preview': isPreview,
      'in-droparea': !!dropArea,
      'is-valid': validity === true,
      'is-invalid': validity === false,
      'draggable-area-valid': validity === true,
      'draggable-area-invalid': validity === false,
      'draggable-area-droparea': !!dropArea,
      'draggable-area-preview': isPreview,
    }, className)}
  >
    <svg width="100%" height="100%" viewBox="0 0 16 6" version="1.1" xmlns="http://www.w3.org/2000/svg" className={cx(styles.handle, 'drag-handle-icon')}>
      <path d="M15,0l-14,0c-0.55,0 -1,0.45 -1,1c0,0.55 0.45,1 1,1l14,0c0.55,0 1,-0.45 1,-1c0,-0.55 -0.45,-1 -1,-1Zm-14,6l14,0c0.55,0 1,-0.45 1,-1c0,-0.55 -0.45,-1 -1,-1l-14,0c-0.55,0 -1,0.45 -1,1c0,0.55 0.45,1 1,1Z" style={{ fillRule: 'nonzero' }} />
    </svg>
    {children}
  </div>
));
