import { TFormValue, TPrimitive } from '../Types';

export type TValidatorRuleList = {
  [key: string]: (value: TPrimitive | TPrimitive[], parameters: string, values?: TFormValue) => boolean;
};

export const RuleList: TValidatorRuleList = {
  required: (value) => {
    const valueArray = Array.isArray(value) ? value : [value];
    return valueArray.length > 0 && valueArray.every((val) => !(val === undefined || val === '' || val === null));
  },
  regex: (value, parameters) => {
    const valueArray = Array.isArray(value) ? value : [value];
    const customRegex = new RegExp(parameters);
    return valueArray.every((item) => item && customRegex.test(item.toString()));
  },
  min: (value, parameters) => {
    const valueArray = Array.isArray(value) ? value : [value];
    const minLength = parseInt(parameters, 10);
    return valueArray.every((val) => (val && val.toString().length >= minLength));
  },
  max: (value, parameters) => {
    const valueArray = Array.isArray(value) ? value : [value];
    const maxLength = parseInt(parameters, 10);
    return valueArray.every((val) => ((val?.toString().length || 0) <= maxLength));
  },
  in_array: (value, parameters) => {
    const valueArray = Array.isArray(value) ? value : [value];
    const options = parameters.split(',');
    return valueArray.every((val) => (options.indexOf(val?.toString() || 'null') !== -1));
  },
  is_email: (value) => {
    const valueArray = Array.isArray(value) ? value : [value];
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return valueArray.every((val) => emailRegex.test(String(val).toLowerCase()));
  },
};
