import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useModals } from '../../Providers/Modals/useModals';
import styles from './Modal.module.css';

const cx = classNames.bind(styles);

/**
 * The modal switch renders the modal component with a backdrop when set with the ModalProvider
 */
export const ModalSwitch = () => {
  const { Modal, closeModal } = useModals();
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (Modal) {
      disableBodyScroll(container.current, {
        reserveScrollBarGap: true,
      });
      return () => {
        clearAllBodyScrollLocks();
      };
    }
  }, [Modal, container]);

  if (Modal) {
    const className = Modal?.data?.className || null;
    return (
      <div ref={container} className={cx(styles.base, className)}>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <Modal.Component
          closeModal={closeModal}
          {...(Modal.data || {})}
        />
      </div>
    );
  }
  return null;
};
