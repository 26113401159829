import React, {
  PropsWithChildren,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ModalContext, IModalContext } from './ModalContext';

/**
 * Provides methods for showing a modal.
 *
 * Note: ModalSwitch should be loaded in your App tree somewhere!
 */
export const ModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [queue, setQueue] = useState<IModalContext['queue']>([]);
  const [currentModal, setCurrentModal] = useState<IModalContext['current']>(false);

  const open = useCallback<IModalContext['open']>((Component, data = {}, priority = 0) => {
    const newModal = { Component, data, priority };
    if (currentModal && (currentModal.priority || 0) < priority) {
      const prevModal = { ...currentModal };
      setQueue((modalQueue) => {
        modalQueue.push(prevModal);
        modalQueue.sort((a, b) => (a.priority > b.priority ? -1 : 1));
        return [...modalQueue];
      });
      setCurrentModal(newModal);
    } else {
      setQueue((modalQueue) => {
        modalQueue.push(newModal);
        modalQueue.sort((a, b) => (a.priority > b.priority ? -1 : 1));
        return [...modalQueue];
      });
    }
  }, [currentModal]);

  const close = useCallback<IModalContext['close']>(() => {
    setCurrentModal(false);
  }, []);

  useEffect(() => {
    if (!currentModal && queue.length > 0) {
      const nextModal = queue.shift();
      if (nextModal) {
        setQueue(queue);
        setCurrentModal(nextModal);
      }
    }
  }, [currentModal, queue]);

  const state = useMemo(() => ({
    queue,
    current: currentModal,
    open,
    close,
  }), [queue, currentModal, open, close]);

  return (
    <ModalContext.Provider value={state}>
      {children}
    </ModalContext.Provider>
  );
};
