import { RuleList } from './rules';
import { TFormFieldValidationMessage, TFormValidator } from '../Types';

/**
 * Validates a value based on a set of rules.
 * @param field {object} The value under validation
 * @param values
 * @returns {valid: boolean, messages: [], id: *}
 */
export const FormValidator: TFormValidator = (field, values) => {
  const { rules } = field;
  const { value } = field;
  const ruleList = (typeof rules === 'string') ? rules.split('|') : rules || [];

  const messages: TFormFieldValidationMessage[] = [];
  ruleList.forEach((rule) => {
    const ruleName = rule.split(':')[0];
    const ruleSegments = [ruleName, rule.split(':').slice(1).join(':')];

    if (!RuleList[ruleSegments[0]](value, ruleSegments[1], values)) {
      messages.push({
        message: ruleSegments[0],
        parameter: ruleSegments[1],
        valid: false,
      });
    }
  });

  return {
    id: field.id,
    messages,
    valid: !messages.some((error) => error.valid === false),
  };
};
