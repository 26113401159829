import { useContext } from 'react';
import { ModalContext } from './ModalContext';

export function useModals() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('The ModalProvider needs to be initialized first!');
  }
  return {
    Modal: context.current,
    openModal: context.open,
    closeModal: context.close,
    modalQueue: context.queue,
  };
}
