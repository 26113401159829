import React, { Component, FunctionComponent, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorModal } from './ErrorModal/ErrorModal';
import { ErrorBlock } from './ErrorBlock/ErrorBlock';

export type TErrorComponent = FunctionComponent<{
  content: TProps['content'],
  error: TState['error']
}>;

type TProps = {
  scope?: {
    [key: string]: any;
  };
  /** Content will be shown within the existing base ErrorBlock or ErrorModal component */
  content?: ReactNode | string;
  /** Component will be loaded instead of default ErrorBlock or ErrorModal components */
  block?: boolean;
  /** Component that is loaded when error is caught. Receives the `error` and possibly `content` */
  component?: TErrorComponent;
};

type TState = {
  error: null | Error;
};

export class ErrorBoundary extends Component<TProps, TState> {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidCatch(error, { componentStack }) {
    this.setState({ error });
    const { scope: givenScope } = this.props;
    if (!error.type || error.type !== 'Exception') {
      Sentry.configureScope((scope) => {
        scope.setExtra('component_stack', componentStack);
        if (givenScope) {
          scope.setExtra('context', givenScope);
        }
      });
      Sentry.captureException(error);
    }
  }

  render() {
    const { error } = this.state;
    const {
      block, component, content, children,
    } = this.props;
    if (error) {
      if (component) {
        const ErrorComponent = component;
        return <ErrorComponent content={content} error={error} />;
      }
      return block
        ? <ErrorBlock content={content} error={error} />
        : <ErrorModal content={content} error={error} />;
    }
    return children;
  }
}
