import { createContext, Dispatch, SetStateAction } from 'react';
import { IConfig } from './ConfigInterface';

export interface IConfigContext extends Partial<IConfig> {
  isDevelopment: boolean;
  isStaging: boolean;
  isProduction: boolean;
}

export type TConfigContext = [IConfigContext, Dispatch<SetStateAction<IConfigContext>>];

export const ConfigContext = createContext<null | TConfigContext>(null);
