import { useCallback, useEffect, useMemo } from 'react';

type TRefs<T extends HTMLElement = any> = React.MutableRefObject<null | T> | Array<React.MutableRefObject<null | T>>;
type THandler = (event: MouseEvent) => void;

/**
 * Adds event listeners for clicks outside the provided referenced HTML nodes
 *
 * Note: From https://usehooks.com/useOnClickOutside/
 */
export function useOnClickOutside(refs: TRefs, handler: THandler): void {
  const handle = useCallback(handler, [handler]);
  const elements = useMemo(() => (Array.isArray(refs) ? refs : [refs]), [refs]);
  useEffect(() => {
    const listener = (event) => {
      if (elements.some((ref) => ref.current?.contains(event.target))) {
        return;
      }

      handle(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [elements, handle]);
}
