import React, { ChangeEventHandler, useCallback } from 'react';
import classNames from 'classnames/bind';
import { useDataSelection } from '@brainstud/universal-components';
import styles from './TrThTd.module.css';
import { Checkbox } from '../../../Form Input';
import { Td } from './Td';
import { Th } from './Th';

const classes = classNames.bind(styles);

interface Props extends Omit<React.ComponentProps<'td'>, 'id' | 'onChange'> {
  /**
   * The id or ids that are toggled when the checkbox is checked.
   *
   * When the DataProvider is set, it will toggle the ID(s) in the selection.
   */
  id: string | string[];
  /**
   * Controls the checked state of the checkbox
   *
   * Note: this is automatically handled when using the `DataProvider` with the context selection.
   */
  checked?: boolean;
  /** Event handler for the checkbox that fires on change */
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

/**
 * Renders a cell with a checkbox within.
 *
 * By default, the checkbox will toggle selection of the provided id(s)
 * to the DataProvider selection context. You can override this behavior
 * by setting an `onChange` handler.
 */
export const TdCheckbox = ({
  id, onChange, checked, className, style, children, ...props
}: Props) => {
  const context = useDataSelection(true);
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    event.stopPropagation();
    if (onChange) {
      return onChange(event);
    }
    if (Array.isArray(id)) {
      context?.handleToggleSelectAll(id);
    } else {
      context?.handleToggleSelect([id]);
    }
  }, [context, id, onChange]);

  const Cell = Array.isArray(id) ? Th : Td;

  const isChecked = checked || (!!context
    && ((Array.isArray(id) && context.selection.length === id.length)
    || (!Array.isArray(id) && context.selection.includes(id)))
  );

  return (
    <Cell
      className={classes(styles.base, styles['td-checkbox'], className)}
      onClick={(event) => event.stopPropagation()}
      style={style}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Checkbox
        quiet
        className={classes(styles.checkbox, 'ui-table__checkbox')}
        checked={isChecked}
        onChange={handleChange}
      />
    </Cell>
  );
};
