import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom/server';
import classNames from 'classnames/bind';
import { useDropzoneUpload } from '@brainstud/universal-components';
import styles from './UploadBox.module.css';
import { UploadBoxProps } from './UploadBoxProps';
import { PreviewUpload } from '../PreviewUpload';

const cx = classNames.bind(styles);

/**
 * This Upload box provides a markup, styling and functionality based on DropzoneJS for uploading files.
 *
 * All configuration options of [dropzonejs](https://dropzonejs.com#configuration-options) are available as props.
 */
export const UploadBox = ({
  quiet, expand, children, onFileRemove, preview = true, label, className, style, headers, ...options
}: PropsWithChildren<UploadBoxProps>) => {
  const { dropContainer, container } = useDropzoneUpload({
    previewTemplate: ReactDOM.renderToStaticMarkup(<PreviewUpload hidden={!preview} onRemove={onFileRemove} />),
    ...(preview !== true && !!preview ? { previewsContainer: preview } : {}),
    headers: { ...headers, 'Content-Type': undefined as unknown as string },
    ...options,
  });

  return (
    <div className={cx(styles.base, { quiet, expand }, className)} style={style} ref={container}>

      <div className={cx('upload-container', 'dropzone')} ref={dropContainer} />

      {!children ? (
        <button type="button" className={cx(styles.button, 'file-uploader-clickable')}>
          <svg className={cx(styles.plus)} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z" />
          </svg>
          {label && (
            <span className={cx(styles.label)}>
              {label}
            </span>
          )}
        </button>
      ) : children}

      <div className={cx(styles.dropFilesMessage)}>
        <svg className={cx(styles.hoverIcon)} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
          <path d="M5,10h4v6h6v-6h4l-7-7L5,10z" className={cx(styles.hoverIconArrow)} />
          <path d="M5,20h14v-2H5V20z" className={cx(styles.hoverIconBottom)} />
        </svg>
      </div>

    </div>
  );
};
