import {
  ReactNode, isValidElement, useCallback, useEffect, useMemo, useState,
} from 'react';
import { isPresent } from 'ts-is-present';

type Option = {
  /** Index of the option in the list */
  idx: number;
  /** Value of the option as string */
  value?: string,
  /** The label displayed to the user */
  label: string | ReactNode,
  /** When true, it makes the paddings of the option smaller */
  thin?: boolean;
  /** When true, it stays on top even with sorting options (used for placeholder) */
  presort?: boolean;
  /** when true, it is not filtered when searching */
  persist?: boolean;
  /** When true, the option cannot be selected */
  disabled?: boolean;
};

type ChangeHandler = (option: Option) => void;
type SearchHandler = (search?: string) => void;

type Parameters = {
  /** List of options that can be selected, each adhering to option object format */
  options: Option[] | ReactNode[],
  /** The selected value */
  value?: string;
  /** The option that is shown as a placeholder when nothing is selected */
  placeholder: string;
  /** Whether you can set the value to null once you have selected something */
  required?: boolean;
  /** A function that is used to filter the option list */
  filter: (value: any) => boolean;
  /** A function that is used to sort the option list */
  sort: (a: any, b: any) => -1 | 0 | 1;
  /** Fires function when changing value */
  onChange: (option: Option) => void;
};

/**
 * This hook is used to manage a list of options that can be selected, based on
 * either React children or an option list.
 */
export function useOptionList({
  options, value, placeholder, required, filter, sort, onChange,
}: Parameters) {
  const placeholderOption = useMemo<Option>(() => ({
    idx: -1,
    label: placeholder,
    thin: true,
    presort: true,
  }), [placeholder]);
  const hasPlaceholder = placeholder && !required;

  // Calculate the options
  const list = useMemo(() => {
    let output: Option[];
    if (Array.isArray(options) && options.length > 0 && !options.some(isValidElement)) {
      output = options as Option[];
    } else {
      const children = options;
      const childrenArray = Array.isArray(children) || children === undefined ? children : [children];
      output = childrenArray ? childrenArray.map((option, idx) => (isValidElement(option) ? {
        idx,
        value: option.props.value || option.props.children,
        label: option.props.children,
        disabled: option.props.disabled,
        ...option.props,
      } : undefined)).filter(isPresent) : [];
    }
    if (hasPlaceholder) {
      output = [placeholderOption, ...output];
    }
    if (sort) {
      output = [
        ...output.filter((option) => option.presort),
        ...output.filter((option) => !option.presort)
          .sort((typeof sort === 'function') ? sort : (a, b) => (((a.label || '') > (b.label || '')) ? 1 : -1))];
    }
    output.filter(isPresent);
    return filter ? output.filter(filter) : output;
  }, [hasPlaceholder, filter, options, sort, placeholderOption]);

  // Calculate the correct default option
  const defaultOption = useMemo(() => {
    const basedOnValueProp = list.find((item) => item && item.value === value);
    return basedOnValueProp || (placeholder ? placeholderOption : list[0]);
  }, [list, value, placeholder, placeholderOption]);

  const [visibleOptions, setVisibleOptions] = useState(list);

  const [selectedOption, setSelectedOption] = useState(defaultOption || list[0]);

  // Update value when value prop is updated
  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);

  const handleChange = useCallback<ChangeHandler>((option: Option) => {
    if (option.disabled) return;
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  }, [onChange]);

  // Filter based on search input
  const handleSearch = useCallback<SearchHandler>((searchValue?: string) => {
    if (!searchValue || (searchValue && searchValue.trim() === '')) {
      setVisibleOptions(list);
    } else if (searchValue) {
      setVisibleOptions(list.filter((option) => option.label?.toString().toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 || option.persist));
    }
  }, [list]);

  return useMemo(() => ({
    /** The option selected by default */
    default: defaultOption,
    /** The currently selected option */
    selected: selectedOption,
    /** A method to filter the option list by option label */
    search: handleSearch,
    /** The list of options */
    list,
    /** The list of visible options (after filtering et cetera) */
    options: visibleOptions,
    /** A method to change the currently selected option */
    handleChange,
    /** @deprecated Use handleChange instead. */
    handleClick: handleChange,
    /** A method to check if an option is currently selected */
    isSelected: (item: Option) => selectedOption.value === item.value,
  }), [defaultOption, selectedOption, handleSearch, list, visibleOptions, handleChange]);
}
