export interface IException extends Error {
  type?: string;
  isCustomException?: boolean;
  actions?: {
    [key: string]: () => void;
  }
}

/**
 * An exception is an 'calculated' error. For example, when
 * a user has no enrollment, it results in error, however, it is an expected use case.
 * @property type {string} The type of error
 */
export class Exception extends Error implements IException {
  public type: string;

  public isCustomException: boolean;

  public actions: {
    [t:string] : () => void;
  };

  constructor(message?: string) {
    super(message);
    this.isCustomException = true;

    this.type = 'Exception';

    this.actions = {
      go_back: () => window.history.go(-1),
      reload: () => window.location.reload(),
      close: () => window.close(),
    };
  }
}
