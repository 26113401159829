import React from 'react';
import classNames from 'classnames/bind';
import styles from './ConfirmationModal.module.css';
import { useModal } from '../useModal';
import { Button } from '../../Buttons';
import { ConfirmationModalProps } from './ConfirmationModalProps';

const cx = classNames.bind(styles);

export const ConfirmationModal = ({
  children,
  className,
  style,
  title,
  question,
  description,
  yes,
  no,
  onClose,
  closeModal,
  onClickOutside,
  handleConfirm,
}: ConfirmationModalProps) => {
  const {
    handleClose, ref: modalElement, hasCloseButton, animate,
  } = useModal({
    onClose,
    closeable: true,
    closeModal,
    onClickOutside,
  });

  return (
    <div className={cx(styles.base, { 'animate-in': animate }, className)} ref={modalElement} style={style}>
      {(hasCloseButton)
      && (
      <button
        type="button"
        className={cx('btn-close')}
        aria-label="Sluiten | Close" // FixMe: Make button translated to language
        onClick={() => handleClose()}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path
            className={cx(styles.cross)}
            d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
          />
        </svg>
      </button>
      )}
      <div className={cx(styles.content)}>
        {title
        && <h3 className={cx(styles.title)}>{title}</h3>}
        {(description || question) && <p>{description || question}</p>}
        {children}
      </div>
      {(yes || no) && (
        <div className={styles.footer}>
          {yes && <Button onClick={handleConfirm}>{yes}</Button>}
          {no && <Button onClick={() => handleClose()} quiet>{no}</Button>}
        </div>
      )}
    </div>
  );
};
