import React, {
  CSSProperties, ReactNode, useEffect, useMemo, useRef,
} from 'react';
import { DragObjectWithType, DragSourceMonitor, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ConnectDragSource } from 'react-dnd/lib/interfaces';
import { useFormConnect } from '../Form';
import { useDragArea } from './useDragArea';

export interface IDraggabilityProps {
  /** Unique identifier to connect to the form */
  identifier: string;
  /** A name that verifies against the droparea 'accept' property */
  name: string;
  /** Whether the drag area should be considered valid according to form validation */
  valid?: boolean;
  /** The form validation rules the drop area should adhere to */
  rules?: string[];
  /** The current drop area the drag area is located (automatically filled) */
  dropArea?: string;
  /** Event listener that is fired when this element is dropped */
  onDrop?: (item: DragObjectWithType, monitor: DragSourceMonitor) => void;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
}

type TDraggabilityAppendedProps = {
  /** The current drop area the drag area is located */
  dropArea?: string;
  /** Whether the DragArea component is currently used as a dragging preview */
  isPreview?: boolean;
  /** Whether the original item is dragged (not true on preview) */
  isDragging: boolean;
  /** Whether the item is dropped on a dropzone */
  isDropped: boolean;
  /** Whether the item is still draggable */
  isDraggable: boolean;
  /** Whether the item has been validated as correct */
  validity?: boolean;
  element?: ConnectDragSource;
};

/**
 * A higher order component that adds drag ability to another component.
 * @param DragArea
 */
export const withDraggability = <Props extends {} = {}>(DragArea: React.FC<Props & TDraggabilityAppendedProps>) => (props: Props & IDraggabilityProps) => {
  const {
    identifier, name, dropArea, children, valid: defaultValid, rules, className, style, onDrop,
  } = props;

  const DragPreview = useMemo(() => (
    DragArea({
      isPreview: true,
      isDraggable: false,
      isDragging: false,
      isDropped: false,
      ...props,
    })
  ), [props]);

  const item = useMemo(() => ({
    identifier,
    name,
    type: name,
    children,
    preview: DragPreview,
    className,
    style,
  }), [name, identifier, DragPreview, children, className, style]);

  const inputElement = useRef({ id: identifier, name: identifier, type: 'none' });
  const {
    triggerUpdate, valid, value, exists,
  } = useFormConnect(identifier, inputElement, rules || []);

  if (!exists) {
    throw Error('Currently Drag and Drop is only possible within a Form component.');
  }

  const {
    addToList, removeFromList, removeFromAllLists, isListed,
  } = useDragArea(item);

  const [{ isDragging }, dragElement, previewHandler] = useDrag({
    item,
    canDrag: !!dropArea || !isListed,
    end: (dragObject, monitor) => {
      if (monitor.didDrop()) {
        const dropAreaName = monitor.getDropResult().dropArea;
        triggerUpdate(dropAreaName);
        removeFromAllLists(item);
        addToList(dropAreaName, {
          ...item,
          dropArea: dropAreaName,
        });
        if (dragObject) {
          onDrop?.(dragObject, monitor);
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const validity = valid === undefined ? defaultValid : valid;

  useEffect(() => {
    if (exists && !!value && !isListed) {
      addToList(value, {
        ...item,
        dropArea: value,
      });
    }
  }, [value, isListed, item, addToList, exists]);

  /**
   * This useEffect is used to sync the value from the form if it is changed by the form
   */
  useEffect(() => {
    if (exists && !value && dropArea && isListed) {
      removeFromList(dropArea, item);
      triggerUpdate('');
    }
  }, [exists, value, dropArea, isListed, triggerUpdate, removeFromList, item]);

  useEffect(() => {
    previewHandler(getEmptyImage(), { captureDraggingState: true });
  }, [previewHandler]);

  return (
    <DragArea
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      validity={validity}
      element={dragElement}
      isDragging={isDragging}
      isDropped={isListed}
      isDraggable={!!dropArea || !isListed}
    >
      {children}
    </DragArea>
  );
};
