import React, { useState, useRef, FC } from 'react';
import classNames from 'classnames/bind';
import { useOnClickOutside } from '@brainstud/universal-components';
import styles from './ActionMenu.module.css';
import { ActionMenuProps } from './ActionMenuProps';

const cx = classNames.bind(styles);

export const ActionMenu: FC<ActionMenuProps> = ({
  id,
  className,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const actionMenu = useRef<HTMLDivElement>(null);
  useOnClickOutside([actionMenu], () => setOpen(false));

  return (
    <div className={cx(styles.root, className)} ref={actionMenu}>
      <button
        type="button"
        className={cx(styles.button)}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        aria-controls={id}
        aria-haspopup="true"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
      </button>
      {open && (
        <div
          id={id}
          className={styles.popover}
          role="menu"
        >
          {children}
        </div>
      )}
    </div>
  );
};
