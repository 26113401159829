export default {

  greetings: {
    day: ({ name }) => `Greetings${name ? ` ${name}` : ''},`,
    morning: ({ name }) => `Good morning${name ? ` ${name}` : ''},`,
    afternoon: ({ name }) => `Good afternoon${name ? ` ${name}` : ''},`,
    evening: ({ name }) => `Good evening${name ? ` ${name}` : ''},`,
    night: ({ name }) => `Goon night${name ? ` ${name}` : ''},`,
    welcome: ({ name, returning }) => `Welcome${returning ? ' back' : ''}${name ? ` ${name}` : ''},`,
  },

  datetime: {
    on: 'on',
    at: 'at',
    between: 'between',
    through: 'through',
    from: 'from',
    after: 'after',
    before: 'before',
    oclock: "o''clock",
    until: 'until',
  },

  errors: {
    default: {
      title: 'Something went wrong.',
      description: 'We received a message with this error and we will fix this as soon as possible. Our apologies for this inconvenience..',
      details: 'Technical details:',
    },
  },

  exceptions: {
    UnauthenticatedException: {
      title: 'You are currently not logged in.',
      description: 'You do not have access to this page. It is possible that you automatically logged out due to a timeout. Try refreshing the page to login again.',
      actions: {
        reload: 'Refresh page',
      },
    },
    NotEnrolledException: {
      title: 'You are not enrolled in this course.',
      description: 'You do not have an enrollment for this course (yet). First enroll into this course before opening.',
      actions: {
        reload: 'Refresh page',
      },
    },
    NotFoundException: {
      title: 'This content could not be found.',
      description: 'We could not find the content you are looking for. Please go back and try again.',
    },
  },

  go_back: 'Go back',
  go_home: 'Go to main page',
  next: 'Next',
  previous: 'Previous',
  agree: 'Agree',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
};
