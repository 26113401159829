import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import styles from './ErrorBlock.module.css';
import { useTranslator } from '../../../Providers/Translator';
import { IException } from '../../../Errors/Exception';

const cx = classNames.bind(styles);

type TProps = {
  error: IException;
  content: ReactNode | string;
  image?: string;
};
/**
 * A component with a default layout for displaying error state of (a part of) the application
 */
export const ErrorBlock = ({ error, content, image }: TProps) => {
  const { name } = error;
  const type = error?.type?.toLowerCase() || 'error';

  const TranslationContext = useTranslator();

  const t = TranslationContext
    ? TranslationContext[0]
    : (input, data, fallback = '') => fallback;

  const title = TranslationContext
    ? t(`${type}s.${name}.title`, {}, t(`${type}s.default.title`))
    : 'An error occurred.';

  return content ? (
    <React.Fragment>
      {content}
    </React.Fragment>
  ) : (
    <div className={cx(styles.base)}>
      {image && <img src={image} alt="" className={cx(styles.image, 'errorblock-image')} />}

      <h3 className="errorblock-title">{title}</h3>
      <p className={cx('errorblock-description', 'description')}>
        {t(`${type}s.${name}.description`, {}, t('errors.default.description', {}, ''))}
      </p>

      <div className={cx(styles.actions, 'errorblock-actions')}>
        {error?.actions ? Object.keys(error.actions).map((key) => (
          <button type="button" key={key} onClick={error?.actions?.[key]}>
            {t(`${type}s.${name}.actions.${key}`, {}, t(`${type}s.default.actions.${key}`, {}, t(key)))}
          </button>
        ))
          : (
            <a href="/">
              {t(`${type}s.${name}.actions.go_home`, {}, t(`${type}s.default.actions.go_home`, {}, 'Go to homepage'))}
            </a>
          )}
      </div>
    </div>
  );
};
