import React, { PropsWithChildren } from 'react';
import { useFormField, useSyncRefs } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import styles from './Input.module.css';
import { InputProps } from './InputProps';

const cx = classNames.bind(styles);

const Input = React.forwardRef<HTMLInputElement, PropsWithChildren<InputProps>>(({
  id,
  name,
  value,
  defaultValue,
  rules,
  valid: defaultValid,
  hidden,
  type,
  style,
  className,
  size,
  inline,
  label,
  placeholder,
  tabIndex,
  readOnly,
  disabled,
  autoComplete,
  onBlur,
  onFocus,
  onChange,
  onInput,
  onInvalid,
  onReset,
  onSubmit,
  onContextMenu,
}, ref) => {
  const inputElement = useSyncRefs(ref, null);

  const identifier = typeof id === 'number' ? `${name}_${id}` : (id || name);
  const { value: controlledValue, setValue, field } = useFormField({
    id: name ? identifier : undefined,
    name,
    value,
    defaultValue,
    rules,
  });

  const { valid, messages } = field || {};
  const validity = valid === undefined ? defaultValid : valid;

  const isHidden = (hidden || type === 'hidden');
  const overflowing = (inputElement.current?.clientWidth || 250) < 250;
  const hasErrors = messages && messages.length > 0;

  return isHidden ? (
    <input
      type="hidden"
      ref={inputElement}
      id={identifier}
      name={name}
      defaultValue={defaultValue}
      className={cx(className)}
    />
  ) : (
    <div
      className={cx(styles.base, {
        'has-errors': hasErrors,
        'is-inline': size || inline,
        'is-valid': validity === true,
        'is-invalid': validity === false,
        'is-required': rules?.includes('required'),
      }, className)}
      style={{ ...style, width: size ? `${size}%` : undefined }}
    >
      {label && (
        <label htmlFor={identifier} id={`${identifier}_label`} className={cx('label')}>
          {label}
        </label>
      )}
      <input
        name={name}
        id={identifier}
        type={type || 'text'}
        placeholder={placeholder}
        tabIndex={tabIndex}
        readOnly={readOnly}
        disabled={disabled}
        autoComplete={autoComplete}
        onBlur={onBlur}
        value={['string', 'number'].includes(typeof controlledValue) ? String(controlledValue) : ''}
        aria-label={!label && !!placeholder ? placeholder : undefined}
        aria-labelledby={label ? `${id}_label` : undefined}
        onFocus={onFocus}
        onChange={(event) => {
          setValue(event.target.value);
          onChange?.(event);
        }}
        onInput={onInput}
        onInvalid={onInvalid}
        onReset={onReset}
        onSubmit={onSubmit}
        onContextMenu={onContextMenu}
        ref={inputElement}
        className={cx('input', {
          'has-text': (inputElement.current && inputElement.current.value !== ''),
        })}
      />
      <div role="alert" className={cx('message', { 'has-messages': hasErrors, 'is-overflowing': overflowing })}>
        {hasErrors && messages![0]}
      </div>
    </div>
  );
});

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  label: '',
  rules: [],
  hidden: false,
  readOnly: false,
  autoComplete: 'on',
};

export { Input };
