import { createContext } from 'react';

export interface ITranslatorContext {
  /**
   * The translate method receives a dot notation specifying the translation string. When a function,
   * it will pass the data to that function, expecting a string in return. If all fails, it uses the fallback string
   * if provided.
   */
  translate: (input: string, data?: object, fallback?: string) => string;
  /** Set the language type (eg. education / business) and country notation (eg. nl, en) */
  setLanguage: (type: string, language: string) => void;
}

export const TranslatorContext = createContext<null | ITranslatorContext>(null);
