import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import styles from './Status.module.css';
import { StatusProps } from './StatusProps';

const cx = classNames.bind(styles);

export const Status = ({
  inline, scheme, outline, style, className, children,
}: PropsWithChildren<StatusProps>) => {
  const schemeClass = scheme && styles[scheme];
  const classList = cx(styles.base, schemeClass, { outline, inline }, className);

  return !inline ? (
    <div style={style} className={classList}>
      {children}
    </div>
  ) : (
    <span style={style} className={classList}>
      {children}
    </span>
  );
};
