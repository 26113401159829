import React, { CSSProperties, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import styles from '../Table.module.css';

const classes = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * Renders the footer of the Table
 */
export const TFoot = ({ className, style, children }: PropsWithChildren<Props>) => (
  <tfoot className={classes(styles.footer, className)} style={style}>
    {children}
  </tfoot>
);
