import React from 'react';
import classNames from 'classnames/bind';
import styles from './Panel.module.css';
import { PanelProps } from './PanelProps';

const cx = classNames.bind(styles);

/**
 * Shows a basic white panel that stands out as a panel
 * @param children
 * @param pad
 * @param style
 * @param className
 * @constructor
 */
export const Panel = ({
  children, pad, style, className,
}: PanelProps) => (
  <div className={cx(styles.base, { pad, unpad: !pad }, 'panel-component', className)} style={style}>
    {children}
  </div>
);
