import {
  createContext, Dispatch, KeyboardEventHandler, MouseEventHandler, useContext,
} from 'react';
import { TDropdownActionTypes, TDropdownState } from './DropdownReducer';

export interface IDropdownContext {
  name?: string;
  search?: string;
  multiple?: boolean;
  defaultValue?: string | string[] | null;
  state: TDropdownState
  dispatch: Dispatch<TDropdownActionTypes>
  handleToggleSelect: MouseEventHandler<HTMLLIElement>&KeyboardEventHandler<HTMLLIElement>;
}

export const DropdownContext = createContext<null | IDropdownContext>(null);

export function useDropdownContext() {
  const context = useContext(DropdownContext);

  if (!context) {
    throw Error('The DropdownContext is required for this hook.');
  }

  return context;
}
