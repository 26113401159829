export const DefaultTheme = {
  version: 1,
  styles: {
    'border-radius': '5px',
    'body-font-family': '"Eina 03", "Open Sans", "Helvetica Neue", sans-serif',
  },
  colors: {
    'focus-color': '255, 78, 78',
    'primary-color-auto': '77, 124, 254',
    'accent-color': '77, 124, 254',
    'brand-color-auto': '100, 0, 205',
    'success-color': '69, 208, 154',
    'warning-color': '255,204,65',
    'error-color': '255,78,78',
    'info-color': '77, 124, 254',
    'light-grey-color-auto': '190,190,210', // Simple default
    'grey-color-auto': '100,100,120', // Simple default
    'dark-grey-color-auto': '60, 60, 80', // Simple default
    'light-background-color': '240, 242, 255', // Simple default
    'background-color': '60, 60, 80', // Simple default
    'dark-text-color': '60, 60, 80',
    'light-text-color': '255, 255, 255',
  },
};
