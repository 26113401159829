import {
  createContext, FunctionComponent,
} from 'react';

type TModal = {
  /** The component to render as a modal */
  Component: FunctionComponent<any>,
  /** The priority the modal should get over other modals */
  priority?: number;
  /** The data that should be provided to the modal as props */
  data?: {
    className?: null | string;
    [key: string]: any;
  },
};

type RequiredKeys<Type, Keys extends keyof Type> = Exclude<Type, Keys> & Required<Pick<Type, Keys>>;

export interface IModalContext {
  current: false | RequiredKeys<TModal, 'priority'>;
  queue: RequiredKeys<TModal, 'priority'>[];
  open: (Component: TModal['Component'], data?: TModal['data'], priority?: TModal['priority']) => void;
  close: () => void;
}

export const ModalContext = createContext<null | IModalContext>(null);
