import React, { ReactNode, useMemo } from 'react';
import classNames from 'classnames/bind';
import { ProgressMarker } from './ProgressMarker';
import styles from './Progress.module.css';
import { ProgressProps } from './ProgressProps';

const cx = classNames.bind(styles);

/**
 * A uniform way of showing a participant's progress, with the possibility to add markers along the bar
 */
export const Progress = ({
  indicator,
  label,
  loading,
  value,
  max = 100,
  steps = false,
  markers = [],
  className,
  style,
}: ProgressProps) => {
  const ended = value > max;

  // eslint-disable-next-line no-mixed-operators
  const oneStepWidth = (1 / (max - 1) * 100);

  const percentage = Math.min(100, Math.max(0, (oneStepWidth * value) - (0.5 * oneStepWidth))); // (value / max * 100) - (.5/max*100) + 1;
  const dots = useMemo(() => {
    const list: ReactNode[] = [];
    for (let idx = 0; idx < (max - 1); idx += 1) {
      list.push(
        <div
          key={`dot_${idx}`}
          style={{ width: `${oneStepWidth}%` }}
          className={cx('step-dot', { 'visual-dot': steps })}
        >
          {markers?.length > 0 && markers.filter((mark) => {
            const step = (React.isValidElement(mark) ? mark.props.step : mark.step) - 1;
            return step === idx;
          }).map((Mark) => {
            if (React.isValidElement(Mark)) {
              return React.cloneElement(Mark, { key: `mark-${Mark.key}` });
            }
            const { content, ...props } = Mark;
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <ProgressMarker key={`mark-${Mark.step}`} {...props}>{content}</ProgressMarker>;
          })}
        </div>,
      );
    }
    return list;
  }, [markers, oneStepWidth, max, steps]);

  return (
    <div className={cx(styles.base, className)} style={style}>
      {!loading && (
        <React.Fragment>
          <div className={cx(styles.steps)}>
            <div className={cx('dots-container', 'relative-container')}>
              {dots.map((dot) => dot)}
            </div>
          </div>

          <div className={cx(styles['track-container'])}>
            <div className={cx(styles['progress-tooltip'])}>
              {label && <span>{label}</span>}
            </div>
            <div
              className={cx(styles.track, { 'is-full': percentage >= 100 })}
              style={{ width: `${!ended ? percentage : 100}%` }}
            >
              <div className={cx('indicator')}>
                <div className={cx('relative-container')}>
                  {!ended && (
                    <div className={cx('indicator-icon')}>
                      {indicator}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
