import {
  MutableRefObject, RefCallback, useEffect, useRef,
} from 'react';

/**
 * Combines two refs into one. Handy for forwarded refs.
 */
export function useSyncRefs<T = any>(ref: null | MutableRefObject<null | T> | RefCallback<T>, defaultValue: null | T) {
  const innerRef = useRef<T>(defaultValue);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      // eslint-disable-next-line no-param-reassign
      ref.current = innerRef.current;
    }
  });

  return innerRef;
}
