import React from 'react';
import classNames from 'classnames/bind';
import styles from './ContentModal.module.css';
import { useModal } from '../useModal';
import { ContentModalProps, ContentModalSubComponentProps } from './ContentModalProps';

const cx = classNames.bind(styles);

/**
 * A modal that can be used to show any kind of content
 *
 * The content modal if relatively large and is therefore suitable for more complex
 * data structures. The component has two subcomponents: Header and Footer. Those can
 * be used to create a structured view for actions such as navigation or a close button.
 */
export const ContentModal = ({
  onKeyUp,
  children,
  className,
  style,
  onClose,
  closeable,
  closeModal,
  onClickOutside,
}: ContentModalProps) => {
  const {
    handleClose,
    ref: modalElement,
    hasCloseButton,
    animate,
  } = useModal({
    onClose,
    onKeyUp,
    closeable,
    closeModal,
    onClickOutside,
  });

  return (
    <div
      className={cx(styles.base, { 'animate-in': animate }, className)}
      ref={modalElement}
      style={style}
    >
      {hasCloseButton && (
        <button
          type="button"
          className={cx('btn-close')}
          onClick={handleClose}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path className={cx('cross')} d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
          </svg>
        </button>
      )}
      {children}
    </div>
  );
};

ContentModal.Header = ({ children, className, style }: ContentModalSubComponentProps) => (
  <header className={cx(styles.header, className)} style={style}>
    <div className={cx('header-inner', 'ui-content-modal-header__inner')}>
      {children}
    </div>
  </header>
);
ContentModal.Footer = ({ children, className, style }: ContentModalSubComponentProps) => (
  <footer className={cx(styles.footer, className)} style={style}>
    {children}
  </footer>
);
