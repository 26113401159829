/* eslint-disable class-methods-use-this */
import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/en-US';
import formatter from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';

type TDate = undefined | Date | number | string;
type TFormatterOptions = Exclude<Parameters<typeof formatter>, undefined>[2];

const locales = {
  nl,
  en,
};

/**
 * The DateFormatHelper helps with formatting dates based on the locale. It works with the date-fns package to format
 * the dates.
 */
class DateFormatHelper {
  public locale: keyof typeof locales = 'en';

  private translator: (input: string) => string;

  constructor(language: keyof typeof locales = 'en') {
    this.locale = language;
  }

  /**
   * Set the locale if it exists
   */
  setLocale(language: string, translator?: (input: string) => string) {
    if (language in locales) {
      this.locale = language as keyof typeof locales;
    }
    if (translator) {
      this.translator = translator;
    }
  }

  private translate(input: string) {
    return this.translator
      ? this.translator(`datetime.${input}`)
      : input;
  }

  /**
   * Makes sure the input is a Javascript Date object.
   */
  private static stringToDate(input: TDate) {
    if (typeof input === 'string') {
      return new Date(input);
    }
    return input || 0;
  }

  /**
   * A general purpose formatting, which receives the set locale as option already.
   */
  public toFormat(input: TDate, format: string, options: TFormatterOptions = {}) {
    if (!input) return input;
    const translatedFormat = (
      format
        .replace('[on]', `'${this.translate('on')}'`)
        .replace('[at]', `'${this.translate('at')}'`)
        .replace('[until]', `'${this.translate('until')}'`)
        .replace('[between]', `'${this.translate('between')}'`)
        .replace('[through]', `'${this.translate('through')}'`)
        .replace('[before]', `'${this.translate('before')}'`)
        .replace('[after]', `'${this.translate('after')}'`)
        .replace('[from]', `'${this.translate('from')}'`)
        .replace('[oclock]', `'${this.translate('oclock')}'`)

    );
    return formatter(DateFormatHelper.stringToDate(input), translatedFormat, {
      locale: locales[this.locale],
      ...options,
    });
  }

  /**
   * Transforms the given date to '[name of day] [day of month] [name of month].
   * Default example: 'Monday 3 January'
   */
  public toDayDate(input: TDate, format = 'cccc d MMM', options?: TFormatterOptions) {
    return this.toFormat(input, format, options);
  }

  /**
   * Transforms the given date to '[name of day] [day of month] [name of month].
   * Default example: 'Monday 3 January'
   */
  public toDayDateTime(input: TDate, format = 'cccc d MMM \'\'yy [at] HH:mm', options?: TFormatterOptions) {
    return this.toFormat(input, format, options);
  }

  /**
   * Transforms the given date to '[day of month] [abbreviated month] [year] [hours]:[minutes]'
   * Default example: '3 Jan. 1992 16:55'
   */
  public toDateTime(input: TDate, format = 'd MMM y [at] HH:mm', options?: TFormatterOptions) {
    return this.toFormat(input, format, options);
  }

  /**
   * Transforms the given date to '[day of month] [name of month] [year]'
   * Default example: '3 January 1992'
   */
  public toDate(input: TDate, format = 'd MMMM y', options?: TFormatterOptions) {
    return this.toFormat(input, format, options);
  }

  /**
   * Returns the specific time of day '[hour]:[minutes]'
   * Default example: '16:55'
   */
  public toTime(input: TDate, format = 'HH:mm', options?: TFormatterOptions) {
    return this.toFormat(input, format, options);
  }

  public toPeriod(startDate: TDate, endDate: TDate, format = 'cccc d MMM \'\'yy [from] HH:mm', options?: TFormatterOptions) {
    const start = DateFormatHelper.stringToDate(startDate);
    const end = DateFormatHelper.stringToDate(endDate);
    if (!end) {
      return this.toDateTime(start, format.replace('[from]', '[at]'), options);
    }
    return (isSameDay(start, end))
      ? `${this.toDayDate(start, format, options)} ${this.translate('until')} ${this.toTime(end, 'HH:mm', options)}`
      : `${this.toDayDate(start, format, options)} ${this.translate('until')} ${this.toDayDate(end, format, options)}`;
  }

  /**
   * Transforms the given date to '[day of month] [abbreviated month] [2 year digits]'
   * Default example: "3 Jan. '92"
   */
  public toAbbreviatedDate(input: TDate, format = "d MMM ''yy", options?: TFormatterOptions) {
    return this.toFormat(input, format, options);
  }

  /**
   * Return TRUE when the input date is within a number of days and after a from date (default now).
   *
   * @param input The date that is tested to be in the timeframe of a set of days
   * @param days The number of days that is added to the 'from date' (default now)
   * @param from The date to calculate from, defaults to now.
   */
  public isWithinDays(input: TDate, days: number, from: TDate = new Date()) {
    if (!input) return false;
    if (!from) return false;
    const inputDate = DateFormatHelper.stringToDate(input);
    const fromDate = DateFormatHelper.stringToDate(from);
    const futureDate = addDays(fromDate, days);
    return (
      isBefore(inputDate, futureDate)
      && isAfter(inputDate, fromDate)
    );
  }

  public toRelativeWithinDays(input: TDate, days: number = 3, format = '[on] d MMM y [at] HH:mm', relativeTo = new Date()) {
    if (!input) {
      return input;
    }
    const inputDate = DateFormatHelper.stringToDate(input);
    const nowDate = DateFormatHelper.stringToDate(relativeTo);
    const futureDate = addDays(inputDate, days);
    return !isAfter(nowDate, futureDate)
      ? this.toRelativeDateUntil(input, true, relativeTo)
      : this.toDateTime(input, format);
  }

  /**
   * Transforms the input date to a relative text string.
   * Default example: '10 minutes ago'
   * @param input The input date that is transformed
   * @param suffix Whether or not to add the suffix 'ago' or 'until'.
   * @param relativeTo
   */
  public toRelativeDateUntil(input: TDate, suffix: boolean = true, relativeTo: TDate = new Date()) {
    if (!input) {
      return input;
    }
    return formatDistance(DateFormatHelper.stringToDate(input), DateFormatHelper.stringToDate(relativeTo), {
      locale: locales[this.locale],
      addSuffix: suffix,
    });
  }
}

const DateFormatter = new DateFormatHelper();
export { DateFormatter };
