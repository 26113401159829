import { useContext } from 'react';
import { TranslatorContext } from './TranslatorContext';

/**
 * Retrieves translations from context and makes accessible through methods
 */
export function useTranslator() {
  const context = useContext(TranslatorContext);

  if (!context) {
    throw Error('Translator Provider is required.');
  }

  const { translate, setLanguage } = context;

  return [translate, {
    setLanguage,
    ucf: (string) => string.charAt(0).toUpperCase() + string.slice(1),
    lc: (string) => string.toLowerCase(),
    uc: (string) => string.toUpperCase(),
    currentDayPeriod: (() => {
      const currentHour = new Date().getHours();
      if (currentHour < 5) {
        return 'night';
      } if (currentHour < 12) {
        return 'morning';
      } if (currentHour < 18) {
        return 'afternoon';
      }
      return 'evening';
    })(),
  }] as const;
}
