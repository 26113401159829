import React, { useRef } from 'react';
import { createDndContext, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const DndContext = createDndContext(HTML5Backend);

/**
 * This provider prevents that multiple DND Backends occur at the same time.
 * From: https://github.com/react-dnd/react-dnd/issues/186#issuecomment-561631584
 */
export const DndProviderWithBackend = ({ children }) => {
  const manager = useRef<typeof DndContext>(DndContext);
  return (
    <DndProvider manager={manager.current.dragDropManager!}>
      {children}
    </DndProvider>
  );
};
