/* eslint-disable no-param-reassign */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { FormTranslations } from './translations';
import { FormContext } from './FormContext';
import { FormValidator } from './Validator/FormValidator';
import { FormTranslator } from './Translator/FormTranslator';
import { useEventHandler } from '../../Hooks';
import {
  IFormContext,
  IFormHandlers,
  IFormMethods,
  IFormState,
  TField,
  TFormEventNames,
  TFormProps,
  TFormResponse,
  TInputList,
  TPrimitive,
} from './Types';

/**
 * Handles input / output for the custom Form Components from the UI library
 */
export function Form<
  TFormInput extends TInputList = TInputList,
  TFormSuccessResponse = TFormResponse,
  TFormErrorResponse = TFormResponse,
  >(
  {
    action,
    validate: shouldValidate,
    onSubmit,
    onError,
    onSuccess,
    onChange,
    onSave,
    onLoad,
    onReset,
    onRestore,
    onRestored,
    onValidationError,
    language,
    disabled: defaultDisabled = false,
    enctype,
    method,
    className,
    style,
    feedback,
    autoStore,
    autoReset,
    storage,
    children,
    translations: customTranslations,
    translator: customTranslator,
    validator: customValidator,
  }: TFormProps<TFormInput, TFormSuccessResponse, TFormErrorResponse>,
) {
  const element = useRef<HTMLFormElement>(null);

  const [fields, setFields] = useState<TField[]>([]);
  const [defaultFields, setDefaultFields] = useState<TField[]>([]);
  const [names, setNames] = useState<string[]>([]);
  const [disabled, setDisabled] = useState<IFormState['disabled']>(defaultDisabled);
  const [response, setResponse] = useState<IFormState['response']>('');
  const [validated, setValidated] = useState<boolean>(false);

  const [restored, setRestored] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(true);

  const { subscribe, emit } = useEventHandler<TFormEventNames>();

  const setField = useCallback((id, properties: Partial<TField & { messages$: TField['messages'] }> = {}) => {
    setFields((prevFields) => {
      const newFields = [...prevFields];
      const fieldIdx = newFields.findIndex((f) => f.id === id);
      const props = Object.keys(properties).reduce((result, property) => {
        if (property[property.length - 1] === '$') {
          const propKey = property.substr(0, property.length - 1);
          result[propKey] = [...(newFields[fieldIdx][propKey] || []), ...properties[property]];
        } else if (property[0] === '$') {
          const propKey = property.substr(1);
          result[propKey] = [...properties[property], ...(newFields[fieldIdx][propKey] || [])];
        } else {
          result[property] = properties[property];
        }
        return result;
      }, {});
      const field = { ...newFields[fieldIdx], ...props };
      newFields.splice(fieldIdx, 1, field);
      return newFields;
    });
  }, []);

  const setValueByName = useCallback((id: string, name: string, value: TPrimitive) => {
    fields
      .filter((field) => field.name === name)
      .forEach((field) => {
        setField(field.id, id === field.id ? { value } : { value: null });
      });
  }, [setField, fields]);

  const values = useMemo<Partial<TFormInput>>(() => fields.reduce((valueList, field) => {
    if (!field.value) return valueList;
    if (valueList[field.name] && field.isArray) {
      valueList[field.name].push(field.value);
    } else {
      valueList[field.name] = field.isArray ? [field.value] : field.value;
    }
    return valueList;
  }, {}), [fields]);

  const messages = useMemo<IFormState['messages']>(() => fields.reduce((messageList, field) => {
    if (field.messages) {
      messageList[field.name] = [...(messageList[field.name] || []), ...field.messages];
    }
    return messageList;
  }, {}), [fields]);

  const state = useMemo<IFormState<TFormInput>>(() => ({
    values,
    fields,
    messages,
    response,
    disabled,
  }), [values, fields, messages, response, disabled]);

  /**
   * Initialize a field into the form
   * @type function
   * @param id {string} A unique identifier for the field
   * @param name {string} A name with which fields can be grouped.
   * @param value {string} The value of the field.
   * @param fieldRules {string|array<string>} The rules that apply to this field
   * @param isArray {boolean} Whether this field should be treated as an array
   */
  const initialize = useCallback<IFormMethods['initialize']>((id, name, value, fieldRules, isArray) => {
    // Add field to name array if it does not already exist there
    if (!names.includes(name)) {
      setNames((prevNames) => [...prevNames, name]);
    }
    // Normalize and add rules
    const normalizedRuleList = Array.isArray(fieldRules) ? fieldRules : (fieldRules ? fieldRules.split('|') : []);
    // Add field and default field
    const field = {
      id, name, value, rules: normalizedRuleList, isArray,
    };
    if (!fields.some((f) => f.id === id)) {
      setFields((prevFields) => ([...prevFields, field]));
      setDefaultFields((prevDefaultField) => ([...prevDefaultField, { ...field }]));
      emit('init', field);
    }
  }, [names, fields, emit]);

  /**
   * Updates the value of the field group, but leaves the default value alone.
   * @type function
   * @param id {string} The unique identifier of the field group
   * @param value {string} The default value of the field group
   */
  const update = useCallback<IFormMethods['update']>((id, value) => {
    if (value === undefined) return;
    const field = fields.find((item) => item.id === id);
    if (field) {
      emit('update', { field, value });
      if (field.isArray) {
        setField(id, { value });
      } else {
        setValueByName(id, field.name, value);
      }
    }
  }, [fields, emit, setField, setValueByName]);

  useEffect(() => {
    emit('change', values);
  }, [emit, values]);

  const validate = customValidator || FormValidator;

  const translate = useCallback((errors) => {
    const code = language || 'en';
    const translations = { ...(FormTranslations[code] || {}), ...customTranslations };
    return customTranslator ? customTranslator(errors, translations) : FormTranslator(errors, translations);
  }, [customTranslator, language, customTranslations]);

  const reset = useCallback<IFormMethods['reset']>((event) => {
    if (storage) {
      window.localStorage.removeItem(storage);
    }
    setFields(JSON.parse(JSON.stringify(defaultFields)));
    setResponse('');
    setResponse('');
    setDisabled(defaultDisabled);
    emit('reset');
    if (!event) {
      element.current?.reset();
    }
    return true;
  }, [element, defaultDisabled, storage, defaultFields, emit]);

  const handleValidation = useCallback<IFormHandlers['handleValidation']>((fieldRange) => {
    const outcomes = fields.reduce<ReturnType<typeof validate>[]>((results, field) => {
      if (fieldRange
        && !fieldRange.includes(field.id)
        && !fieldRange.some((item) => typeof item !== 'string' && item.name === field.name)) {
        // If the field range is given, do _not_ validate items that are _not_ in the field range
        return results;
      }
      const result = validate(field, values[field.name]);
      return result ? [...results, result] : results;
    }, []);
    outcomes.forEach((outcome) => {
      setField(outcome.id, {
        ...outcome,
        messages: translate(outcome.messages.filter((message) => message.valid === false)),
      });
    });
    setValidated(true);
    return !outcomes.some((item) => item.valid === false);
  }, [fields, values, validate, translate, setField]);

  useEffect(() => {
    if (validated) {
      emit('validated', fields);
      setValidated(false);
    }
  }, [validated, fields, emit]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    if (isSubmitted) {
      emit('submit', fields);
      setIsSubmitted(false);
    }
  }, [isSubmitted, fields, emit]);

  const handleSuccess = useCallback((httpResponse = {}) => {
    if (httpResponse.message) {
      setResponse(httpResponse.message);
    }
    if (feedback !== 'full') setDisabled(false);
    if (autoReset && !autoStore) reset();
    return onSuccess ? onSuccess(httpResponse) : null;
  }, [feedback, autoReset, autoStore, onSuccess, reset]);

  const handleError = useCallback((httpResponse) => {
    setDisabled(false);
    if (httpResponse?.status !== 422 && httpResponse?.statusCode !== 422) {
      if (httpResponse?.error) {
        setResponse(httpResponse?.error);
      }
      return onError ? onError(httpResponse) : null;
    }
    const message = httpResponse?.data?.message;
    if (message) setResponse(message);
    const errors = httpResponse?.data?.errors || httpResponse?.errors;
    if (errors) {
      if (Array.isArray(errors)) {
        // JSON:API Compliant
        errors.forEach((error) => {
          const identifier = error?.source?.pointer || error?.source?.parameter;
          const pointer = identifier && identifier.split('/').slice(1);
          const errorFields = fields.filter((field) => field.name === pointer[0] || field.name === identifier);
          const fieldId = errorFields[pointer[1] || (errorFields.length - 1)]?.id;
          if (fields.some((field) => field.id === fieldId)) {
            setField(fieldId, { messages$: [error.detail] });
          }
        });
      } else {
        // Non-JSON:API Compliant
        Object.keys(errors).forEach((key) => {
          const pointer = key.split('.');
          const errorFields = fields.filter((field) => field.name === pointer[0]);
          const fieldId = errorFields[pointer[1] || (errorFields.length - 1)]?.id;
          if (fields.some((field) => field.id === fieldId)) {
            setField(fieldId, { messages: errors[key] });
          }
        });
      }
    }
    return onValidationError ? onValidationError() : null;
  }, [fields, setField, onError, onValidationError]);

  const handleSubmit = useCallback<IFormHandlers['handleSubmit']>((event) => {
    if (event) {
      event.preventDefault();
    }
    if (!disabled) {
      setDisabled(true);
      if (shouldValidate && !handleValidation()) {
        setIsSubmitted(true);
        if (feedback !== 'full') setDisabled(false);
        return onValidationError && onValidationError(values, messages);
      }
      setIsSubmitted(true);
      if (onSubmit) {
        try {
          const process = onSubmit(values as TFormInput);
          if (!!process && 'then' in process) {
            process.then((httpResponse) => {
              if (!!httpResponse && ((httpResponse.status || httpResponse.statusCode || 300) <= 299)) {
                emit('success', httpResponse);
              } else {
                emit('error', httpResponse);
              }
            })
              .catch((error) => {
                emit('error', error);
              });
          } else {
            emit('success', process);
          }
        } catch (e) {
          emit('error', e);
        }
      }
    }
  }, [shouldValidate, messages, onSubmit, onValidationError, feedback, disabled, handleValidation, values, emit]);

  /* Store the form on submission to localStorage if asked */

  const store = useCallback<IFormMethods['store']>(() => {
    if (storage) window.localStorage.setItem(storage, JSON.stringify(state));
  }, [storage, state]);

  const save = useCallback<IFormMethods['save']>((args, callbacks) => {
    if (typeof onSave === 'function') {
      try {
        return onSave(state, args, callbacks);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error trying to save the form with the onSave function. Stored form state in localStorage.', e);
        return store();
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn("The property 'onSave' does not exist. Using local storage to save the form state.", onSave);
      return store();
    }
  }, [state, store, onSave]);

  const handleStorage = useCallback(() => {
    if (typeof onSave === 'function') {
      try {
        onSave(state);
      } catch (e) {
        store();
        // eslint-disable-next-line no-console
        console.error('Saved answer to local storage.', e);
      }
    } else if (autoStore) store();
  }, [store, autoStore, onSave, state]);

  const handleRestore = useCallback((restorableState: Partial<IFormState>) => {
    setFields([
      ...(restorableState.fields || []),
      ...defaultFields.filter((defaultField) => (
        !restorableState.fields?.some((field) => defaultField.id === field.id)
      )),
    ]);
    setResponse(restorableState.response || '');
    setDisabled(restorableState.disabled || false);
    setRestored(true);
    emit('restore', restorableState);
  }, [emit, defaultFields]);

  /* Restore the form whenever it is loaded and available */
  const restore = useCallback(() => {
    if (storage && window.localStorage) {
      const stored = window.localStorage.getItem(storage);
      if (stored) {
        const restoredState = JSON.parse(stored);
        if (restoredState.fields.length === fields.length) {
          handleRestore(restoredState);
        }
      }
    }
  }, [storage, fields, handleRestore]);

  /* Build the form context from methods, handlers and data */
  const methods = useMemo<IFormMethods>(() => ({
    initialize,
    update,
    subscribe,
    store,
    save,
    restore,
    reset,
  }), [initialize, update, subscribe, store, restore, reset, save]);

  const handlers = useMemo<IFormHandlers>(() => ({
    handleValidation,
    handleSubmit,
  }), [handleSubmit, handleValidation]);

  const data = useMemo(() => ({
    ...state,
    defaultFields,
  }), [state, defaultFields]);

  const context = useMemo<IFormContext<TFormInput>>(() => ({
    feedback,
    ...data,
    ...methods,
    ...handlers,
  }), [data, feedback, methods, handlers]);

  useEffect(() => {
    const unsubscribers = [
      subscribe('submit', handleStorage),
      subscribe('success', handleSuccess),
      subscribe('error', handleError),
      ...(onChange ? [subscribe('change', onChange)] : []),
      ...(onRestore ? [subscribe('restore', onRestore)] : []),
    ];
    return () => {
      unsubscribers.forEach((unsubscribe) => !!unsubscribe && unsubscribe());
    };
  }, [subscribe, handleStorage, handleSuccess, handleError, onChange, onRestore, onRestored]);

  useEffect(() => {
    setDisabled(defaultDisabled);
  }, [defaultDisabled]);

  useEffect(() => {
    if (onLoad && !loaded) {
      const loadedState = onLoad();
      if (loadedState) {
        handleRestore(loadedState);
        setLoaded(true);
      }
    }
  }, [onLoad, handleRestore, loaded]);

  useEffect(() => {
    if (onLoad) {
      setLoaded(false);
    }
  }, [onLoad]);

  useEffect(() => {
    if (!restored && !loaded) restore();
  }, [loaded, restored, restore]);

  return (
    <FormContext.Provider value={context}>
      <form
        action={action}
        className={className}
        style={style}
        method={method}
        ref={element}
        onSubmit={handleSubmit}
        onReset={(event) => {
          if (onReset?.(event) !== false) {
            reset(event);
          }
        }}
        encType={enctype}
      >
        {typeof children === 'function' ? children(context) : children}
      </form>
    </FormContext.Provider>
  );
}

Form.defaultProps = {
  disabled: false,
  validate: true,
  autoStore: false,
  autoReset: true,
  language: 'en',
  method: 'POST',
  feedback: 'standard',
  onReset: () => true,
};
