import { ReactNode, useContext, useEffect } from 'react';
import { FlowContext, IFlowContext } from './FlowContext';

type FlowStepProps = {
  labels?: IFlowContext['labels'],
  next?: IFlowContext['next'],
  previous?: IFlowContext['previous'],
  children: ReactNode | ReactNode[] | ((context: IFlowContext) => ReactNode | ReactNode[]);
};

const FlowStep = ({
  labels, next, previous, children,
}: FlowStepProps) => {
  const flow = useContext(FlowContext);

  if (!flow) {
    throw new Error('A FlowStep component needs to be used within a Flow component');
  }

  const { set, defaults } = flow;

  useEffect(() => {
    if (labels) {
      set({
        labels,
      });
    }

    set({
      next: next || defaults.next,
      previous: previous || defaults.previous,
    });

    return () => {
      set({
        next: defaults.next,
        previous: defaults.previous,
      });
    };
  }, [set, defaults, labels, next, previous]);

  return typeof children === 'function' ? children(flow) : children;
};
FlowStep.displayName = 'FlowStep';

export { FlowStep };
