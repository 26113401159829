export default {

  routes: {
    account: 'account',
    course: 'programma',
    category: 'hoofdstuk',
    subcategory: 'paragraaf',
    quiz: 'toets',
    assignment: 'opdracht',
    logout: 'logout',
  },

  datetime: {
    on: 'op',
    at: 'om',
    between: 'tussen',
    through: 't/m',
    from: 'van',
    after: 'na',
    before: 'voor',
    oclock: 'uur',
    until: 'tot',
  },

  greetings: {
    day: ({ name }) => `Goedendag${name ? ` ${name}` : ''},`,
    morning: ({ name }) => `Goedemorgen${name ? ` ${name}` : ''},`,
    afternoon: ({ name }) => `Goedemiddag${name ? ` ${name}` : ''},`,
    evening: ({ name }) => `Goedenavond${name ? ` ${name}` : ''},`,
    night: ({ name }) => `Goedenacht${name ? ` ${name}` : ''},`,
    welcome: ({ name, returning }) => `Welkom${returning ? ' terug' : ''}${name ? ` ${name}` : ''},`,
  },

  errors: {
    default: {
      title: 'Er is iets fout gegaan.',
      description: 'Er is reeds een melding naar ons gestuurd en wij doen ons best om deze fout spoedig te herstellen. Onze excuses voor het ongemak.',
      details: 'Technische details:',
    },
  },

  exceptions: {
    UnauthenticatedException: {
      title: 'Je bent niet ingelogd.',
      description: 'Momenteel heb je geen toegang tot deze pagina. Het kan zijn dat je (na inactiviteit) automatisch bent uitgelogd. Probeer de pagina te verversen of log (opnieuw) in.',
      actions: {
        reload: 'Laad pagina opnieuw',
      },
    },
    NotEnrolledException: {
      title: 'Je bent niet ingeschreven.',
      description: 'Je bent (nog) niet ingeschreven voor dit onderdeel. Schrijf je in voor dit onderdeel en open deze pagina opnieuw.',
      actions: {
        reload: 'Laad pagina opnieuw',
      },
    },
    NotFoundException: {
      title: 'Dit onderdeel is niet gevonden',
      description: 'Het is niet gelukt om dit onderdeel te vinden. Ga terug naar de hoofdpagina of ga naar de vorige pagina.',
    },
  },

  go_back: 'Ga terug',
  go_home: 'Ga naar hoofdpagina',
  next: 'Volgende',
  previous: 'Vorige',
  agree: 'Akkoord',
  cancel: 'Annuleer',
  yes: 'Ja',
  no: 'Nee',
};
