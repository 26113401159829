import React, {
  PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import kebabCase from 'kebabcase-keys';
import { ThemeContext, TThemeContext } from './ThemeContext';
import { useColors } from './useColors';
import { ITheme } from './ThemeInterface';

type TProps = {
  theme?: ITheme,
};

function isV1Theme(theme: any): theme is ITheme {
  return Number(theme.version) === 1;
}

/**
 * The theme provider loads in the assets variables and styles set within
 * the theme object.
 */
export const ThemeProvider = ({ theme: defaultTheme, children }: PropsWithChildren<TProps>) => {
  const styleElement = useRef(document.createElement('style'));
  useEffect(() => {
    styleElement.current.id = 'css_property_colors';
    document.head.appendChild(styleElement.current);
  }, []);

  const [version, setVersion] = useState(0);
  const [colors, setColors] = useState<Required<ITheme>['colors']>({});
  const [styles, setStyles] = useState<Required<ITheme>['styles']>({});
  const [assets, setAssets] = useState<Required<ITheme>['assets']>();
  const [loaded, setLoaded] = useState(false);
  const [cssProperties, setCssProperties] = useState<{ [key: string]: string }>({});

  const cssPropertyColors = useColors(colors);

  const setTheme = useCallback((theme: ITheme | any) => {
    if (isV1Theme(theme)) {
      const {
        version: newVersion, colors: newColors, styles: newStyles, assets: newAssets,
      } = theme;
      setVersion((prevVersion) => newVersion || prevVersion);
      setColors((prevColors) => newColors || prevColors);
      setStyles((prevStyles) => newStyles || prevStyles);
      setAssets(newAssets);
    } else {
      // V0 Theme
      const {
        brandLogo, brandColor, fontFamilyUrl, ...rest
      } = theme;
      setVersion(0);
      setColors((prevColors) => ({
        ...prevColors,
        ...(brandColor && {
          'primary-color': brandColor,
          'brand-color': brandColor,
        }),
      }));
      setStyles((prevStyles) => ({
        ...prevStyles,
        ...rest,
      }));
      setAssets((prevAssets) => ({
        ...prevAssets,
        logo: brandLogo,
        fonts: fontFamilyUrl,
      }));
    }
  }, []);

  useEffect(() => {
    if (!loaded) {
      if (defaultTheme) {
        setTheme(defaultTheme);
        setLoaded(true);
      }
    }
  }, [setTheme, defaultTheme, loaded]);

  const theme = useMemo(() => ({
    version,
    colors,
    styles,
    assets,
  }), [version, colors, styles, assets]);

  const methods = useMemo(() => ({
    setTheme,
    setColors,
    setAssets,
    setStyles,
  }), [setTheme]);

  const state = useMemo<TThemeContext>(() => ([
    theme,
    methods,
  ]), [theme, methods]);

  useEffect(() => {
    const cssPropertyStyles = {};
    const kebabProperties = kebabCase(styles);
    Object.keys(kebabProperties).forEach((styleProperty) => {
      if (kebabProperties[styleProperty]) {
        cssPropertyStyles[`--${styleProperty}`] = kebabProperties[styleProperty];
      }
    });
    setCssProperties({ ...cssPropertyColors, ...cssPropertyStyles });
  }, [cssPropertyColors, styles]);

  const [loadedSheets, setLoadedSheets] = useState<string[]>([]);
  useEffect(() => {
    if (assets?.styles) {
      assets.styles.forEach((stylesheet) => {
        if (!loadedSheets.includes(stylesheet)) {
          const link = document.createElement('link');
          link.type = 'text/css';
          link.rel = 'stylesheet';
          document.head.appendChild(link);
          link.href = stylesheet;
          setLoadedSheets((sheets) => [...sheets, stylesheet]);
        }
      });
    }
  }, [assets, loadedSheets]);

  useLayoutEffect(() => {
    let css = ':root{';
    Object.keys(cssProperties).forEach((cssProperty) => {
      css += `${cssProperty}: ${cssProperties[cssProperty]};`;
    });
    css += '}';
    styleElement.current.innerHTML = css;
  }, [cssProperties]);

  return (
    <ThemeContext.Provider value={state}>
      {children}
    </ThemeContext.Provider>
  );
};
