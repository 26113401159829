import { useFormField, useSyncRefs } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox } from '../../Checkbox/Checkbox';
import { useDropdownContext } from '../DropdownContext';
import styles from './DropdownOption.module.css';
import { DropdownOptionProps } from '../DropdownTypes';

const classes = classNames.bind(styles);

/**
 * A helper component for rendering options which the Dropdown understands
 */
export const DropdownOption = React.forwardRef<HTMLLIElement, DropdownOptionProps>(({
  children,
  persist,
  disabled,
  text,
  small,
  value,
  className,
  style,
}, ref) => {
  const {
    name,
    search,
    dispatch,
    multiple,
    handleToggleSelect,
    state,
    defaultValue,
  } = useDropdownContext();

  const liElement = useSyncRefs(ref, null);
  const [textContent, setTextContent] = useState<string | null | undefined>(text);
  useEffect(() => {
    if (textContent === undefined && liElement.current) {
      setTextContent(liElement.current.textContent);
    }
  }, [liElement, textContent]);

  const isSelected = state.some((item) => item.value === value && item.selected);
  const isDefaultChecked = useMemo(() => (
    value && Array.isArray(defaultValue) ? defaultValue.includes(value) : defaultValue === value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  useFormField({
    id: name ? `${name}_${value}` : undefined,
    name: (
      name
        ? (multiple && !name?.endsWith('[]') ? `${name}[]` : name)
        : undefined
    ),
    defaultValue: value,
    checked: isSelected,
  });

  const isFound = !search || textContent?.toLowerCase().includes(search) || persist;
  useEffect(() => {
    if (value && textContent) {
      dispatch({
        type: 'connect',
        payload: {
          value,
          label: (textContent),
          selected: isDefaultChecked,
        },
      });
      return () => {
        dispatch({
          type: 'disconnect',
          payload: {
            value,
          },
        });
      };
    }
  }, [dispatch, textContent, value, isDefaultChecked]);

  return isFound ? (
    <li
      tabIndex={!disabled ? 0 : -1}
      ref={liElement}
      role="option"
      aria-selected={isSelected}
      className={classes(styles.base, {
        disabled,
        smallItem: small,
        selected: isSelected,
      }, className)}
      style={style}
      data-value={value}
      onKeyPress={!disabled ? handleToggleSelect : undefined}
      onClick={!disabled ? handleToggleSelect : undefined}
    >
      {multiple && !!value && (
        <Checkbox
          className={styles.checkbox}
          quiet
          small
          disabled={disabled}
          checked={isSelected}
        />
      )}
      <div className={classes(styles.inner, 'ui-dropdown-option__inner')}>
        {children}
      </div>
    </li>
  ) : null;
});
