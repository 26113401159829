import {
  DataProviderActions, DataState, IDataItem, REMOVE_FILTER_ACTION, SET_FILTER_ACTION, TActions,
} from './Types';

export function DataReducer<T extends IDataItem>(state: DataState<T>, action: TActions<T>) {
  const setFilter = (payload: SET_FILTER_ACTION<T>['payload'], toggle = false) => {
    const filterNames = Object.keys(payload);
    const filterMethods = Object.values(payload)
      .map((filter) => (typeof filter === 'function' || !filter
        ? filter as (item: T) => boolean
        : (item) => Object.keys(filter).some((key) => item[key] === filter[key])));

    const filters = { ...state.filters };
    filterNames.forEach((name, idx) => {
      if (typeof filterMethods[idx] !== 'function' || (toggle && filters[name])) {
        delete filters[name];
      } else {
        filters[name] = filterMethods[idx];
      }
    });
    return { ...state, filters };
  };
  const unsetFilter = (filterName: REMOVE_FILTER_ACTION['payload']) => {
    const filters = {
      ...state.filters,
    };
    delete filters[filterName];
    return { ...state, filters };
  };

  switch (action.type) {
    case DataProviderActions.SET_FILTER:
      return setFilter(action.payload);
    case DataProviderActions.TOGGLE_FILTER:
      return setFilter(action.payload, true);
    case DataProviderActions.REMOVE_FILTER:
      return unsetFilter(action.payload);
    case DataProviderActions.SEARCH_ON:
      return Object.values(action.payload).some((value) => value !== '' && value !== undefined)
        ? setFilter({
          search: (item) => Object.keys(action.payload).some((key) => {
            if (typeof item[key] === 'string' && typeof action.payload[key] === 'string') {
              const regex = new RegExp(action.payload[key] as string, action.meta?.flags || 'i');
              return regex.test(item[key] as string);
            }
            return item[key] === action.payload[key];
          }),
        })
        : unsetFilter('search');
    case DataProviderActions.SORT_ON:
      return typeof action.payload.method === 'function'
        ? { ...state, sort: action.payload as DataState<T>['sort'] }
        : {
          ...state,
          sort: {
            id: action.payload.id,
            method: ((a, b) => (
              a[action.payload.method as keyof typeof a] < b[action.payload.method as keyof typeof b] ? -1 : 1)
            ),
            direction: action.payload.direction || 'ASC',
          },
        };
    default:
      return state;
  }
}
