/* eslint-disable no-param-reassign */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { DndProviderWithBackend } from './DndProviderWithBackend';
import { DragAndDropContext, IDragAndDropContext } from './DragAndDropContext';
import { DragLayer } from './DragLayer';

/**
 * Instantiates the DragAndDrop Provider that keeps track (via lists) of which item has been dragged to which droparea.
 */
export const DragAndDrop = ({ children }) => {
  const [list, setList] = useState({});
  const createList = useCallback<IDragAndDropContext['createList']>((name) => {
    setList((prevList) => ({
      ...prevList,
      [name]: [...(prevList[name] || [])],
    }));
  }, []);

  const deleteList = useCallback<IDragAndDropContext['deleteList']>((name) => {
    setList((prevList) => {
      if (prevList[name]) {
        delete prevList[name];
      }
      return { ...prevList };
    });
  }, []);

  const addToList = useCallback<IDragAndDropContext['addToList']>((name, item) => {
    setList((prevList) => {
      if (!prevList[name]?.some((i) => i.identifier === item.identifier)) {
        return {
          ...prevList,
          [name]: [...(prevList[name] || []), item],
        };
      }
      return prevList;
    });
  }, []);

  const findInLists = useCallback<IDragAndDropContext['findInLists']>((dragItem, lists) => (
    Object.keys(lists).filter((name) => lists[name].some((item) => item.identifier === dragItem.identifier))
  ), []);

  const removeFromList = useCallback<IDragAndDropContext['removeFromList']>((name, item) => {
    setList((prevList) => ({
      ...prevList,
      [name]: prevList[name].filter((i) => i.identifier !== item.identifier),
    }));
  }, []);

  const removeFromAllLists = useCallback<IDragAndDropContext['removeFromAllLists']>((item) => {
    setList((prevList) => {
      Object.keys(prevList).forEach((name) => {
        prevList[name] = prevList[name].filter((i) => i.identifier !== item.identifier);
      });
      return { ...prevList };
    });
  }, []);

  const resetLists = useCallback(() => {
    setList((prevList) => {
      Object.keys(prevList).forEach((name) => {
        prevList[name] = [];
      });
      return { ...prevList };
    });
  }, []);

  const state = useMemo(() => ({
    list,
    createList,
    deleteList,
    addToList,
    findInLists,
    removeFromList,
    removeFromAllLists,
  }), [list, createList, deleteList, addToList, findInLists, removeFromList, removeFromAllLists]);

  const inputElement = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!inputElement.current) return;
    const element = inputElement.current.form;
    element?.addEventListener('reset', resetLists);
    return () => {
      element?.removeEventListener('reset', resetLists);
    };
  }, [inputElement, resetLists]);

  return (
    <DragAndDropContext.Provider value={state}>
      <input type="hidden" ref={inputElement} />
      <DndProviderWithBackend>
        {children}
        <DragLayer />
      </DndProviderWithBackend>
    </DragAndDropContext.Provider>
  );
};
