import {
  createContext, Dispatch,
} from 'react';
import { ITableState, TTableActions } from '../Reducer/TableReducer';

export interface ITableContext<T extends {} = any> extends ITableState {
  data: T[]
  dispatch: Dispatch<TTableActions>
}

export const TableContext = createContext<null | ITableContext>(null);
