import {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { DragAndDropContext } from './DragAndDropContext';

export function useDropArea(name: string) {
  const context = useContext(DragAndDropContext);

  if (!context) {
    throw new Error('The DragAndDrop provider is required before using this hook.');
  }

  const {
    createList, addToList, list, removeFromList, removeFromAllLists, deleteList,
  } = context;

  useEffect(() => {
    if (name) {
      createList(name);
      return () => {
        deleteList(name);
      };
    }
  }, [name, createList, deleteList]);

  const addToDropList = useCallback((item) => addToList(name, item), [addToList, name]);
  const removeFromDropList = useCallback((item) => removeFromList(name, item), [removeFromList, name]);

  return useMemo(() => ({
    ...context,
    list: list[name] || [],
    addToList: addToDropList,
    removeFromList: removeFromDropList,
    removeFromAllLists,
  }), [name, context, list, addToDropList, removeFromDropList, removeFromAllLists]);
}
