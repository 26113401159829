/* eslint-disable react/jsx-props-no-spreading */
import React, { MouseEventHandler, PropsWithChildren, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './TrThTd.module.css';
import { Button } from '../../../Buttons/Button';

const classes = classNames.bind(styles);

interface Props extends React.ComponentProps<'td'> {
  /** Cell that contains the main data point, which receives extra styling */
  main?: boolean;
  /** An anchor tag to an internal route */
  to?: string;
  /** An anchor tag to an external route */
  href?: string;
  center?: boolean;
  right?: boolean;
}

/**
 * Use the td (table data) cell component within the Table component. It can help with the styling of clickable cells.
 */
export const Td = ({
  to, href, onClick, center, right, main, className, style, children, ...props
}: PropsWithChildren<Props>) => {
  const handleClick = useCallback<MouseEventHandler<HTMLTableCellElement>>((event) => {
    if (onClick) {
      event.stopPropagation();
      return onClick(event);
    }
    return true;
  }, [onClick]);

  return ((onClick || href || to) ? (
    <td
      className={classes(styles.base, styles.main, {
        center,
        right,
      }, 'ui-td__base', 'ui-th__main', className)}
      style={style}
      {...props}
    >
      <Button
        type="button"
        quiet
        to={to}
        href={href}
        onClick={handleClick}
        className={classes(styles.button, 'ui-td__button')}
      >
        {children}
      </Button>
    </td>
  ) : (
    <td
      className={classes(styles.base, {
        main,
        center,
        right,
      }, 'ui-th__base', className)}
      style={style}
      {...props}
    >
      {children}
    </td>
  ));
};
