import Dropzone, { DropzoneFile, DropzoneOptions } from 'dropzone';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';

Dropzone.autoDiscover = false;

interface SuccessResponse {
  data?: {
    id: string;
    type: string;
    attributes: {
      file_name: string;
      file_url: string;
    }
  }
}

interface ErrorResponse {
  errors?: Array<{
    detail: string;
    status: number;
    title: string;
  }>
}

export interface DropzoneConfig extends DropzoneOptions {
  /* The full URL to which the POST request can be send */
  url: DropzoneOptions['url'];
  /** All data that is appended to the file upload request */
  append?: {
    [t:string]: any;
  };
  /** Function executed before first upload */
  onBeforeUpload?: (files?: DropzoneFile[]) => Promise<void>;
  /** Function executed after queue has been completely uploaded */
  onAfterUpload?: (files?: DropzoneFile[]) => Promise<void>;
  /** Function executed just before each file upload, which can modify the XMLHttpRequest */
  onFileUpload?: (file: DropzoneFile, xhr: XMLHttpRequest, formData: FormData) => void;
  /** Function executed after each successful file upload */
  onAfterFileUpload?: (file: DropzoneFile, response: SuccessResponse) => void;
  /** Function executed after each failed upload */
  onUploadError?: (file: DropzoneFile, response: ErrorResponse) => void;
  /** Function executed on file remove handling */
  onRemoveFile?: () => void | Promise<void>;
}

/**
 * A hook for initializing a DropzoneJS component with the different event handlers
 */
export function useDropzoneUpload({
  onBeforeUpload,
  onAfterUpload,
  onAfterFileUpload,
  onFileUpload,
  onUploadError,
  append,
  ...options
}: DropzoneConfig) {
  const container = useRef<HTMLDivElement>(null);
  const dropContainer = useRef<HTMLDivElement>(null);

  const { capture, acceptedFiles } = options;
  const [dropZone, setDropZone] = useState<Dropzone>();
  useEffect(() => {
    if (container.current && dropContainer.current && !dropContainer.current.dropzone) {
      try {
        setDropZone(new Dropzone(dropContainer.current, {
          ...options,
          clickable: [container.current],
        }));
      } catch (e) {
        console.error(e);
      }
    }
  }, [container, dropContainer, options, dropZone]);

  const priorityMimeType = capture ? {
    camcorder: 'video/*',
    camera: 'image/*',
    microphone: 'audio/*',
  }[capture] : undefined;
  useEffect(() => {
    if (dropZone?.options) {
      dropZone.options = {
        ...dropZone.options,
        ...{
          chunking: !!options.chunkSize,
          acceptedFiles: priorityMimeType || (acceptedFiles === '*' ? undefined : acceptedFiles),
          autoProcessQueue: false,
          parallelChunkUploads: false,
          withCredentials: true,
          timeout: 240000,
          clickable: [container.current!],
          ...options,
        },
      };
    }
  }, [dropZone, priorityMimeType, acceptedFiles, capture, options]);

  const processQueue = useCallback(() => {
    if (dropContainer.current && !(dropContainer.current?.dropzone?.options?.url as string).includes('undefined')) {
      dropContainer.current.dropzone.processQueue();
    }
  }, [dropContainer]);

  const handleSending = useCallback((file: Dropzone.DropzoneFile, xhr: XMLHttpRequest, formData: FormData) => {
    if (!formData.has('name')) {
      formData.append('name', file.name);
    }
    if (!formData.has('filename')) {
      formData.append('filename', file.name);
    }
    if (append) {
      Object.values(append).forEach((key) => {
        if (!formData.has(key)) {
          formData.append(key, append[key]);
        }
      });
    }
    onFileUpload?.(file, xhr, formData);
  }, [onFileUpload, append]);

  const handleAddedFile = useCallback(async (files: DropzoneFile[]) => {
    if (onBeforeUpload) {
      await onBeforeUpload(files);
    }
    processQueue();
  }, [processQueue, onBeforeUpload]);

  const handleFileUploaded = useCallback(async (file: DropzoneFile, response: SuccessResponse) => {
    if (onAfterFileUpload) await onAfterFileUpload(file, response);
  }, [onAfterFileUpload]);

  const handleUploadError = useCallback(async (file: DropzoneFile, response: ErrorResponse) => {
    const errorBox = file.previewElement.querySelectorAll<HTMLSpanElement>('[data-dz-errormessage]')[0];
    if (errorBox) {
      errorBox.innerHTML = response?.errors?.map((error) => error.detail).join('<br />') || '';
    }
    await onUploadError?.(file, response);
  }, [onUploadError]);

  const handleQueueComplete = useCallback(async () => {
    if (onAfterUpload) {
      await onAfterUpload(dropZone?.getAcceptedFiles());
      dropZone?.getAcceptedFiles().forEach((file) => dropZone.removeFile(file));
    }
  }, [dropZone, onAfterUpload]);

  useEffect(() => {
    if (dropZone?.on) {
      dropZone.on('sending', handleSending);
      dropZone.on('addedfiles', handleAddedFile);
      dropZone.on('success', handleFileUploaded);
      dropZone.on('error', handleUploadError);
      dropZone.on('queuecomplete', handleQueueComplete);
      return () => {
        dropZone.off('sending', handleSending);
        dropZone.off('addedfiles', handleAddedFile);
        dropZone.off('success', handleFileUploaded);
        dropZone.off('error', handleUploadError);
        dropZone.off('queuecomplete', handleQueueComplete);
      };
    }
  }, [dropZone, handleAddedFile, handleQueueComplete, handleSending, handleFileUploaded, handleUploadError]);

  return {
    dropZone,
    dropContainer,
    container,
  };
}
