import React, {
  PropsWithChildren, useState, useMemo, useCallback,
} from 'react';
import { IPaginationContext, PaginationContext } from './PaginationContext';

type Props = {
  /** Set the starting page: default 1 */
  startingPage?: IPaginationContext['page'],
  /** Set the starting limit: default 25 */
  startingLimit?: IPaginationContext['limit'],
};

/**
 * A PaginationProvider tracks the state of paginated content by providing data about the
 * filter, sorting, page, and limit for the paginated dataset.
 */
export const PaginationProvider = ({ children, startingPage = 1, startingLimit = 25 }: PropsWithChildren<Props>) => {
  const [filter, setFilter] = useState<IPaginationContext['filter']>();
  const [sort, setSort] = useState<IPaginationContext['sort']>();
  const [page, setPage] = useState<IPaginationContext['page']>(startingPage);
  const [limit, setLimit] = useState<IPaginationContext['limit']>(startingLimit);

  const toggleSortBy = useCallback((sortBy: string) => {
    setSort((prevSort) => {
      if (prevSort) {
        if (prevSort.includes(`-${sortBy}`)) {
          const sortArray = prevSort.filter((item) => item !== `-${sortBy}`);
          return sortArray.length ? sortArray : undefined;
        } if (prevSort.includes(sortBy)) {
          return [`-${sortBy}`, ...prevSort.filter((item) => item !== sortBy)];
        }
        return [sortBy, ...prevSort];
      }
      return [sortBy];
    });
  }, []);

  const state = useMemo(() => ({
    filter,
    sort,
    page,
    limit,
    setFilter,
    setSort,
    setPage,
    setLimit,
    toggleSortBy,
  }), [filter, sort, page, limit, toggleSortBy]);

  return (
    <PaginationContext.Provider value={state}>
      {children}
    </PaginationContext.Provider>
  );
};
