import { useContext, useMemo } from 'react';
import { DragAndDropContext, TDragItem } from './DragAndDropContext';

/**
 * Use this hook to attach a drag area to the DragAndDrop provided context. It tracks the current location when
 * it has been dropped on a dropzone.
 * @param item
 */
export function useDragArea(item: TDragItem) {
  const context = useContext(DragAndDropContext);

  if (!context) {
    throw new Error('The DragAndDrop provider is required before using this hook.');
  }

  const {
    findInLists,
    list,
  } = context;

  return useMemo(() => ({
    ...context,
    isListed: findInLists(item, list).length > 0,
  }), [context, findInLists, item, list]);
}
