import React, {
  ChangeEventHandler, useCallback, useMemo, useState,
} from 'react';
import classNames from 'classnames/bind';
import styles from './DropdownGroup.module.css';
import { Checkbox } from '../../Checkbox/Checkbox';
import { useDropdownContext } from '../DropdownContext';
import { DropdownGroupProps } from '../DropdownTypes';

const classes = classNames.bind(styles);

/**
 * Adds a group of options to a dropdown, resulting in a complex menu
 */
export const DropdownGroup = ({
  label,
  small,
  className,
  style,
  children,
}: DropdownGroupProps) => {
  const [open, setOpen] = useState(false);
  const {
    multiple, state, dispatch, search,
  } = useDropdownContext();
  const values = useMemo(() => state.filter((item) => item.selected).map((item) => item.value), [state]);
  const groupValues = [children].flat().map((child) => child.props.value);
  const isAllSelected = groupValues.every((value) => values.includes(String(value)));
  const isIndeterminate = !isAllSelected && groupValues.some((value) => values.includes(String(value)));
  const handleToggleAll = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    event.stopPropagation();
    const selectedGroupValues = groupValues.filter((item) => item !== undefined && values.includes(item));
    const unselectedGroupValues = groupValues.filter((item) => item !== undefined && !values.includes(item));
    dispatch({
      type: 'toggle-multiple',
      payload: {
        value: (selectedGroupValues.length === groupValues.length ? groupValues : unselectedGroupValues) as string[],
      },
    });
  }, [dispatch, groupValues, values]);

  return (
    <div className={classes(styles.base, { small, open, searching: !!search }, className)}>
      <button
        type="button"
        tabIndex={0}
        className={classes(styles.button)}
        style={style}
        onClick={!search ? () => setOpen((prevOpen) => !prevOpen) : undefined}
      >
        {multiple && (
          <Checkbox
            className={styles.checkbox}
            onChange={handleToggleAll}
            indeterminate={isIndeterminate}
            checked={isAllSelected}
            quiet
          />
        )}
        <div className={styles.label}>
          {label}
        </div>
        <svg
          className={classes('chevron-right')}
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          role="presentation"
        >
          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
        </svg>
      </button>
      <div className={classes(styles.panel, { open }, 'ui-dropdown-group_panel')}>
        {children}
      </div>
    </div>
  );
};
