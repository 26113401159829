import { useForm, useOnClickOutside } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import React, {
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Search } from '../Search';
import styles from './Dropdown.module.css';
import { DropdownContext, IDropdownContext } from './DropdownContext';
import { DropdownOption } from './Option';
import { DropdownReducer } from './DropdownReducer';
import { MultiDropdownProps, SingleDropdownProps } from './DropdownTypes';

const cx = classNames.bind(styles);
const MAGIC_OFFSET_WIDTH = 30;

/**
 * Shows a dropdown field with a set of options to choose from.
 * Requires the use of `Dropdown.Option` or `Dropdown.Group` as children.
 *
 * When a `name` is given, the Dropdown is connected to the universal Form component (when context is available).
 */
function BaseDropdown({
  id,
  name,
  title,
  label,
  placeholder,
  rules,
  value: controlledValue,
  defaultValue,
  multiple,
  onChange,
  defaultOpen = false,
  searchable,
  small,
  disabled,
  block,
  className,
  style,
  children,
}: SingleDropdownProps | MultiDropdownProps) {
  const [state, dispatch] = useReducer(DropdownReducer, []);
  const selected = useMemo(() => state.filter((item) => item.selected), [state]);
  const values = useMemo(() => selected.map((item) => item.value), [selected]);
  const isControlled = controlledValue !== undefined;

  useEffect(() => {
    if (controlledValue !== undefined) {
      if (!multiple) {
        dispatch({
          type: 'toggle',
          payload: {
            value: (!Array.isArray(controlledValue) ? controlledValue : controlledValue[0]),
          },
        });
      } else {
        dispatch({
          type: 'select-multiple',
          payload: {
            value: controlledValue,
          },
        });
      }
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [controlledValue, multiple]);

  const [isOpen, setOpen] = useState(defaultOpen);
  const handleToggleOpen = useCallback(() => (
    setOpen((prevIsOpen) => !prevIsOpen)
  ), []);

  const handleToggleSelect = useCallback<IDropdownContext['handleToggleSelect']>((event) => {
    event.preventDefault();
    const newValue: string = event.currentTarget.dataset.value || event.currentTarget.textContent;
    const isPlaceholder = placeholder === newValue;
    if (event.key === 'Enter' || !event.key) {
      if (!isControlled) {
        dispatch({
          type: isPlaceholder || !multiple ? 'toggle' : 'toggle-multiple',
          payload: {
            value: newValue,
          },
        });
      }

      if (!multiple) {
        handleToggleOpen();
        (onChange as SingleDropdownProps['onChange'])?.(!isPlaceholder ? newValue : null);
      } else if (isPlaceholder) {
        (onChange as MultiDropdownProps['onChange'])?.([]);
      } else {
        (onChange as MultiDropdownProps['onChange'])?.(values.includes(newValue)
          ? values.filter((item) => item !== newValue)
          : [...values, newValue]);
      }
    }
  }, [dispatch, handleToggleOpen, multiple, onChange, values, isControlled, placeholder]);

  // Set error state when dropdown is required
  const { subscribe } = useForm(true) || {};
  const [hasErrors, setHasErrors] = useState(false);
  const isRequired = rules?.includes('required');
  useEffect(() => {
    if (subscribe && isRequired) {
      return subscribe('submit', () => {
        setHasErrors(values.length === 0);
      });
    }
  }, [values, isRequired, subscribe]);

  // Reset to default value when Form reset event fires
  useEffect(() => {
    if (subscribe && !isControlled) {
      return subscribe('reset', () => {
        setHasErrors(false);
        dispatch({
          type: 'select-multiple',
          payload: {
            value: defaultValue,
          },
        });
      });
    }
  }, [subscribe, defaultValue, isControlled]);

  const dropdown = useRef<HTMLDivElement>(null);
  const drawer = useRef<HTMLUListElement>(null);

  // Calculate whether to show the options above or be low the input
  const spaceBelow = window.innerHeight - (dropdown.current ? dropdown.current.getBoundingClientRect().bottom : 0);
  const upsideDown = (spaceBelow < Math.min(320, (([children].flat().length || 0) + 1) * 40));

  // Close dropdown on click outside
  useOnClickOutside(dropdown, () => {
    setOpen(false);
  });

  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (!block && drawer.current) {
      const drawerChildren: NodeListOf<Element> = drawer.current.querySelectorAll('[role="option"]');
      if (drawerChildren) {
        setWidth((prevWidth) => [...Array.from(drawerChildren)].reduce((newWidth, item) => (
          Math.max(item.clientWidth + MAGIC_OFFSET_WIDTH, prevWidth)
        ), prevWidth));
      }
    }
  }, [small, drawer, block]);

  const valueLabel = selected?.map((item) => item.label).join(', ') || placeholder;

  const [search, setSearch] = useState<undefined | string>();
  const handleSearch = useCallback<KeyboardEventHandler<HTMLInputElement>>((event) => {
    setSearch(event.currentTarget.value.toLowerCase());
  }, []);
  const handleSearchClose = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.stopPropagation();
    setSearch(undefined);
    setOpen(true);
  }, []);

  const context = useMemo(() => ({
    name,
    search,
    handleToggleSelect,
    multiple,
    state,
    defaultValue,
    dispatch,
    // eslint-disable-next-line react-hooks/exhaustive-deps,react/destructuring-assignment
  }), [dispatch, name, state, search, multiple, handleToggleSelect, defaultValue]);

  const [identifier] = useState(id || `dropdown_${Math.round(Math.random() * 100000)}`);
  return (
    <DropdownContext.Provider value={context}>
      <div
        ref={dropdown}
        className={cx(styles.base, 'ui-dropdown__base', 'dropdown-base', {
          isOpen,
          'has-errors': hasErrors,
          'dropdown-is-open': isOpen,
          'dropdown-has-errors': hasErrors,
          'is-required': !rules?.includes('required'),
          small,
          disabled,
          block,
        }, className)}
        style={style}
      >
        {label && (
          <label id={`${identifier}_label`} className={cx(styles.label, 'ui-dropdown__label', 'dropdown-label')} htmlFor={identifier}>
            {label}
          </label>
        )}

        <button
          type="button"
          id={identifier}
          aria-haspopup="listbox"
          aria-labelledby={label && `${identifier}_label`}
          title={title ? `${title} ${valueLabel}` : valueLabel}
          aria-label={title ? `${title} ${valueLabel}` : valueLabel}
          aria-expanded={isOpen}
          disabled={disabled}
          className={cx(styles.button, 'ui-dropdown__button', 'dropdown-button')}
          style={!block ? { width: width > MAGIC_OFFSET_WIDTH ? `${width}px` : 'auto' } : undefined}
          onClick={handleToggleOpen}
        >
          <span className={cx(styles.valueText)}>{ valueLabel }</span>

          <svg
            className={cx('chevron-down')}
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
          </svg>
        </button>

        {/* Dropdown drawer */}
        <ul
          ref={drawer}
          tabIndex={-1}
          role="listbox"
          style={{
            transform: upsideDown ? 'translateY(calc(-100% - 50px))' : undefined,
            width: !block ? (width > MAGIC_OFFSET_WIDTH ? `${width}px` : 'auto') : '100%',
          }}
          className={cx(styles.drawer, 'ui-dropdown__drawer', 'dropdown-drawer', {
            visible: isOpen,
            upsideDown,
          })}
        >
          {searchable && isOpen && (
            <li key={-1} className={cx(styles['search-input'], 'ui-dropdown__search')}>
              <Search
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                block
                onKeyUp={handleSearch}
                onClose={handleSearchClose}
              />
            </li>
          )}

          {!!placeholder && !rules?.includes('required') && (
          <DropdownOption>
            {placeholder}
          </DropdownOption>
          )}

          {isRequired && state.length === 0 && (
            <li>
              {placeholder}
            </li>
          )}

          {children}
        </ul>
      </div>
    </DropdownContext.Provider>
  );
}

export const Dropdown = ({ children, ...props }: SingleDropdownProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <BaseDropdown {...props}>{children}</BaseDropdown>
);
export const MultiDropdown = ({ children, ...props }: MultiDropdownProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <BaseDropdown {...props} multiple>{children}</BaseDropdown>
);
