import { useContext } from 'react';
import { FormContext } from './FormContext';
import { IFormContext, TInputList } from './Types';

type TOutput<TInput extends TInputList> = {
  messages?: Array<undefined | string>;
  hasErrors: boolean;
  values: IFormContext<TInput>['values'];
  fields: IFormContext<TInput>['fields'];
  subscribe: IFormContext<TInput>['subscribe'];
  save: IFormContext<TInput>['save'];
  reset: IFormContext<TInput>['reset'];
  disabled: boolean;
  response: IFormContext<TInput>['response'];
  store: IFormContext<TInput>['store'];
  restore: IFormContext<TInput>['restore'];
  message: IFormContext<TInput>['response'];
  validate: IFormContext<TInput>['handleValidation'];
  submit: IFormContext<TInput>['handleSubmit'];
};

/**
 * This hook gives access to the methods and content of the FormProvider
 *
 * @param nullable {true} When set to true, it will simply return `null` if FormProvider is not initialized.
 *
 * @throws {Error} When the hook is used without being wrapped in a FormProvider
 */
export function useForm<TFormInput extends TInputList = TInputList>(nullable?: false): TOutput<TFormInput>;
export function useForm<TFormInput extends TInputList = TInputList>(nullable?: true): null | TOutput<TFormInput>;
export function useForm<TFormInput extends TInputList = TInputList>(nullable = false) {
  const context = useContext<null | IFormContext<TFormInput>>(FormContext);
  if (!context) {
    if (!nullable) {
      throw new Error('The useForm hook is used without the FormProvider.');
    }
    return null;
  }

  const {
    save,
    store,
    restore,
    fields,
    reset,
    disabled,
    values,
    response,
    subscribe,
    handleValidation,
    handleSubmit,
  } = context;

  const messages = fields.map((field) => field.messages).flat(2);

  return {
    values,
    fields,
    subscribe,
    save,
    reset,
    messages,
    hasErrors: Object.values(messages).length > 0,
    disabled: disabled || (fields.length > 0 && fields.every((field) => field.locked)),
    response,
    message: response,
    store,
    restore,
    validate: handleValidation,
    submit: handleSubmit,
  } as TOutput<TFormInput>;
}
