import React from 'react';
import classNames from 'classnames/bind';
import { useFormField } from '@brainstud/universal-components';
import styles from './Radio.module.css';
import { RadioProps } from './RadioProps';

const cx = classNames.bind(styles);

/**
 * Renders an input element type radio with appropriate styling and connection the the universal Form component.
 */
export const Radio = React.forwardRef<HTMLInputElement, RadioProps>((
  {
    id,
    name,
    defaultValue,
    rules,
    label,
    prefix,
    checked: isChecked,
    defaultChecked,
    valid: defaultValid,
    onClick,
    onMouseOver,
    onFocus,
    onChange,
    onReset,
    onSubmit,
    onInput,
    onBeforeInput,
    disabled,
    onBlur,
    onInvalid,
    readOnly,
    quiet,
    small,
    children,
    className,
    style,
  }, ref,
) => {
  const identifier = typeof id === 'number' ? `${name}_${id}` : (id || name);
  const {
    setValue, field, value, isFormConnected,
  } = useFormField({
    id: String(identifier),
    name,
    rules,
    checked: isChecked,
    defaultChecked: !!defaultChecked,
    defaultValue,
  });
  const {
    valid, messages, locked, value: fieldValue,
  } = field || {};
  const validity = valid === undefined ? defaultValid : valid;

  return (
    <div
      className={cx(styles.base, {
        'has-errors': messages && messages.length > 0,
        'is-valid': validity === true,
        'is-invalid': validity === false,
        quiet,
        small,
      }, className)}
      style={style}
    >
      <input
        id={identifier}
        type="radio"
        name={name}
        className={cx(styles.input)}
        ref={ref}
        disabled={locked || disabled}
        readOnly={readOnly}
        defaultValue={defaultValue}
        checked={isFormConnected ? (field ? value === fieldValue : !!isChecked) : isChecked}
        defaultChecked={!isFormConnected ? defaultChecked : undefined}
        onInput={onInput}
        onInvalid={onInvalid}
        onBeforeInput={onBeforeInput}
        onChange={(event) => {
          onChange?.(event);
          setValue(event.target.value);
        }}
        onReset={onReset}
        onSubmit={onSubmit}
      />
      <div className={cx(styles.inner)}>
        <div className={cx(styles.circle)}>
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="7" cy="6" r="3.5" className={cx('inner-circle')} />
          </svg>
        </div>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <label
          htmlFor={identifier}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onFocus={onFocus}
          onBlur={onBlur}
          className={cx(styles.label)}
        >
          {prefix && (
            <span className={cx(styles.prefix, 'radio-prefix')}>
              {prefix}
            </span>
          )}
          {label || children}
        </label>
      </div>
    </div>
  );
});
