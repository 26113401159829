import React, { PropsWithChildren, ReactSVG } from 'react';
import classNames from 'classnames/bind';
import styles from './PreviewUpload.module.css';
import { IHTMLBaseProperties } from '../../Types/ElementTypes';

const cx = classNames.bind(styles);

export interface PreviewUploadProps extends IHTMLBaseProperties {
  /** Adds an remove button which calls this method to remove the file from server */
  onRemove?: () => Promise<void> | void;
  /** Size of the file in a human readable string */
  fileSize?: string;
  /** Image to show as a thumbnail with the file */
  thumbnail?: string | ReactSVG;
  /** The amount of progress that has been made */
  progress?: number;
  /** Shows an error message when uploading failed */
  error?: string;
  /** Hides the preview container completely */
  hidden?: boolean;
}

/**
 * Works with DropzoneJS to render an uploading file.
 *
 * For example, you can `ReactDOM.renderToStaticMarkup(<PreviewUpload />)`
 * @param error Can be used to display an error with the upload
 * @param progress The upload progress in percentages
 * @param thumbnail A thumbnail to show
 * @param fileSize A human-readable file size
 * @param onRemove A handler for removing a file after upload
 * @param hidden Hides the preview completely
 * @param className
 * @param style
 * @param children
 */
export const PreviewUpload = ({
  error,
  progress,
  thumbnail,
  children,
  fileSize,
  onRemove,
  hidden,
  className,
  style,
}: PropsWithChildren<PreviewUploadProps>) => (
  <div
    className={cx(styles.base, {
      isError: !!error,
      isSuccess: progress === 100 && !error,
      isHidden: hidden,
    }, 'dz-preview', 'dz-file-preview', 'ui-upload__preview-container', className)}
    style={style}
  >
    <div className={cx(styles.details, 'ui-upload__preview-details')}>

      <div className={cx(styles.thumbnail, 'ui-upload__preview-thumbnail')}>
        {(!thumbnail || typeof thumbnail === 'string') ? (
          <img className={cx(styles.thumbnailImage)} data-dz-thumbnail="true" alt="" src={thumbnail} />
        ) : (
          { thumbnail }
        )}
      </div>

      <div>
        <div className={cx(styles.progress, 'ui-upload__preview-progress')}>
          <span
            className={cx(styles.innerProgress)}
            data-dz-uploadprogress
            style={progress ? { width: `${progress}%` } : undefined}
          />
        </div>
        <div className={cx(styles.indicators, 'ui-upload__preview-indicators')}>
          <div className={cx(styles.indicator, styles.successIcon)}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z"
              />
            </svg>
          </div>
          <div className={cx(styles.indicator, styles.errorIcon)}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M2.73 21h18.53c.77 0 1.25-.83.87-1.5l-9.27-16c-.39-.67-1.35-.67-1.73 0l-9.27 16c-.38.67.1 1.5.87 1.5zM13 18h-2v-2h2v2zm-1-4c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className={cx(styles.name)}>
        <div data-dz-name>
          {children}
        </div>
        <div className={cx(styles.fileSize)} data-dz-size>
          {fileSize}
        </div>
      </div>

      {onRemove && (
      <button className={cx(styles.removeFile)} data-dz-remove onClick={onRemove}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"
          />
        </svg>
      </button>
      )}
    </div>

    <div className={cx(styles.errorMessage, 'dz-error-message')}>
      <span data-dz-errormessage>
        {error}
      </span>
    </div>
  </div>
);
