import React, { PropsWithChildren, useState } from 'react';
import { ConfigContext } from './ConfigContext';
import { IConfig } from './ConfigInterface';

type TProps = {
  config?: IConfig;
};

/**
 * Makes the configuration provided accessible throughout the application
 */
export const ConfigProvider = ({ config: providedConfig, children }: PropsWithChildren<TProps>) => {
  const configStateHandler = useState({
    isDevelopment: process.env.NODE_ENV === 'development',
    isStaging: process.env.NODE_ENV === 'staging',
    isProduction: process.env.NODE_ENV === 'production',
    ...providedConfig,
  });

  return (
    <ConfigContext.Provider value={configStateHandler}>
      {children}
    </ConfigContext.Provider>
  );
};
