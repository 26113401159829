import { useContext } from 'react';
import { FlowContext } from './FlowContext';

/**
 * Makes the FlowContext easily available and calculates whether the current step isFirst or isLast.
 */
export function useFlow() {
  const flow = useContext(FlowContext);

  if (!flow) {
    throw new Error('The `useFlow` hook is used outside of a FlowProvider component.');
  }

  return {
    ...flow,
    isLast: flow.step === flow.total,
    isFirst: flow.step === 1,
  };
}
