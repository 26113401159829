import React, { PropsWithChildren, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './ProgressMarker.module.css';
import { IHTMLBaseProperties } from '../../Types/ElementTypes';

const cx = classNames.bind(styles);

interface Props extends IHTMLBaseProperties {
  /** The location of the marker */
  step: number;
}

/**
 * Shows a marker inside de progress bar can be used to show on what point you unlock something
 */
export const ProgressMarker = ({ style, className, children }: PropsWithChildren<Props>) => {
  const [labelVisible, setLabelVisible] = useState(false);
  const toggleLabel = () => {
    setLabelVisible((prevVisibility) => !prevVisibility);
  };
  return (
    <div className={cx(styles.base, className)} style={style}>
      {children && (
        <div className={cx(styles.label, { 'label-visible': labelVisible })}>
          {children}
        </div>
      )}
      <button type="button" className={cx('mark-button')} onClick={toggleLabel}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <defs>
            <linearGradient id="star_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#FF9300" />
              <stop offset="100%" stopColor="#FFB400" />
            </linearGradient>
          </defs>
          <path
            d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
            style={{ fill: 'url(#star_gradient)' }}
          />
        </svg>
      </button>
    </div>
  );
};
