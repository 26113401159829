import { useContext } from 'react';
import dotProp from 'dot-prop';
import { ConfigContext, TConfigContext } from './ConfigContext';

/**
 * Returns the specified part of the configuration of the app.
 */
export function useConfig(): TConfigContext;
export function useConfig<T = any>(specifier: string): T;
export function useConfig(specifier?: string) {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('The ConfigProvider needs to be initialized first!');
  }

  if (specifier) {
    return dotProp.get(context[0], specifier);
  }

  return context;
}
