import { createContext, Dispatch, SetStateAction } from 'react';
import { ITheme } from './ThemeInterface';

export interface IThemeMethods {
  /** Completely rewrites the theme */
  setTheme: Dispatch<SetStateAction<ITheme>>,
  /** Reconfigure the defined colors in the theme */
  setColors: Dispatch<SetStateAction<ITheme['colors']>>,
  /** Reconfigure the assets loaded in the theme */
  setAssets: Dispatch<SetStateAction<ITheme['assets']>>,
  /** Reconfigure the custom styles loaded as CSS Properties in the theme */
  setStyles: Dispatch<SetStateAction<ITheme['styles']>>,
}
export type TThemeContext = [ITheme, IThemeMethods];

export const ThemeContext = createContext<null | TThemeContext>(null);
