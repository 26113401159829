/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ChangeEventHandler, MutableRefObject, ReactNode, useRef,
} from 'react';
import { useFormConnect } from '../useFormConnect';
import { IFormConnectedElement, TRule } from '../Types';

interface ISelectableTrait {
  selected?: boolean;
  errors?: ReturnType<typeof useFormConnect>['errors']
}

interface Props {
  /** The id to track the selected state with in the form */
  id: string;
  /** A name for the item to use in the form state */
  name: string;
  /** Whether the item is selected by default */
  selected?: boolean;
  /** Whether the item is currently disabled (unable to select/deselect) */
  disabled?: boolean;
  /** Event handler for change event */
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /** Current value of the field when selected */
  value?: string;
  /** Rules to apply to the selected element */
  rules?: TRule[];
  children: (trait: ISelectableTrait) => ReactNode;
}

const Selectable = ({
  id,
  name,
  selected: defaultSelected,
  value: defaultValue,
  rules,
  onChange,
  disabled,
  children,
}: Props) => {
  const element = useRef<IFormConnectedElement>({
    id,
    type: 'radio',
    name,
    value: defaultValue,
    checked: !!defaultSelected,
  });
  const { identifier, errors } = useFormConnect(name, element, rules || []);

  return (
    <label htmlFor={identifier}>
      <input
        type="radio"
        id={identifier}
        name={name}
        ref={element as MutableRefObject<HTMLInputElement>}
        value={defaultValue}
        onChange={onChange}
        disabled={disabled}
        defaultChecked={defaultSelected}
        style={{ display: 'none' }}
      />

      {
        children({
          selected: element.current.checked,
          errors,
        })
      }

    </label>
  );
};

interface WithSelectabilityProps extends Omit<Props, 'children'> {
  selected?: boolean;
  /** @deprecated Use `disabled` instead */
  deactivated?: boolean;
  /** @deprecated Use `onChange` instead */
  onSelect?: ChangeEventHandler<HTMLInputElement>;
  children?: ReactNode | ReactNode[];
}

/**
 * This Higher Order Component wraps a Component with the Selectable component. By doing so, it connects
 * the provided component with a form and adds attributes to the component making it capable of being selected.
 */
export function withSelectability<T extends {} = {}>(Component: React.FC<T>): React.FC<T & WithSelectabilityProps> {
  return ({ selected, ...props }) => (
    <Selectable
      id={props.id}
      selected={selected}
      name={props.name}
      value={props.value}
      rules={props.rules}
      onChange={props.onSelect}
      disabled={props.disabled || props.deactivated}
    >
      {(selectableTrait) => (
        <Component
          selectable
          focusable={!(props.disabled || props.deactivated)}
          {...selectableTrait}
          {...props as T}
        >
          {props.children}
        </Component>
      )}

    </Selectable>
  );
}
