import React, {
  useEffect, useMemo, useReducer,
} from 'react';
import classNames from 'classnames/bind';
import styles from './Table.module.css';
import { TableProps } from './TableProps';
import { TableContext } from './Context/TableContext';
import { TFoot } from './SubComponents/TFoot';
import { THead } from './SubComponents/THead';
import { TableReducer } from './Reducer/TableReducer';
import { TBody } from './SubComponents/TBody';

const classes = classNames.bind(styles);

/**
 * Shows a table with basic formatting and the ability to trigger sorting events.
 *
 * When you use the `Th` component, you can provide a `sortOn` {string} property, which will be returned by the
 * `onSort` method on this Table. That way, you can implement sorting actions based on that string. The string prefixed
 * with a `-` means the requested sorting order is descending, instead of ascending.
 */
export function Table<T>({
  header, onSort, data = [], footer, className, style, children,
}: TableProps<T>) {
  const [state, dispatch] = useReducer(TableReducer, {
    initialized: false,
    header: false,
  });
  const { initialized, sortOn } = state;

  useEffect(() => {
    if (initialized) {
      onSort?.(sortOn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, sortOn]);

  const context = useMemo(() => ({
    ...state,
    data,
    dispatch,
  }), [data, state]);

  return (
    <TableContext.Provider value={context}>
      <div className={classes(styles.base, className)} style={style}>
        <div className={classes(styles.wrap)}>
          <table className={classes(styles.table)}>
            {header && (
              <THead>
                {typeof header === 'function' ? header() : header}
              </THead>
            )}
            {typeof children === 'function' && data ? (
              <TBody>
                {data.map((item, index) => (
                  children(item, index)
                ))}
              </TBody>
            ) : (
              children
            )}
            {footer && (
              <TFoot>
                {typeof footer === 'function' ? footer() : footer}
              </TFoot>
            )}
          </table>
        </div>
      </div>
    </TableContext.Provider>
  );
}
Table.THead = THead;
Table.TBody = TBody;
Table.TFoot = TFoot;
