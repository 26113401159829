import React, { MouseEventHandler, PropsWithChildren, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './TrThTd.module.css';

const classes = classNames.bind(styles);

interface Props extends React.ComponentProps<'tr'> {
  href?: string;
  to?: string;
  /** Indents the first cell to look like a 'child' row */
  indent?: number
}

/**
 * The `Tr` component makes it easier to make an entire row clickable with nice styling
 */
export const Tr = ({
  href, to, onClick, className, style, children, indent, ...props
}: PropsWithChildren<Props>) => {
  const handleClick = useCallback<MouseEventHandler<HTMLTableRowElement>>((event) => {
    if (!event.isPropagationStopped()) {
      if (href) {
        window.location.href = href;
      } else if (to) {
        window.location.href = `#${to}`;
      }
      onClick?.(event);
    }
  }, [href, to, onClick]);
  return (
    <tr
      onClick={handleClick}
      className={classes(styles.base, 'ui-tr__base', {
        clickable: (to || href || onClick),
        'indent-1': indent === 1,
        'indent-2': indent === 2,
        'indent-3': indent === 3,
        'indent-4': indent === 4,
        'indent-5': indent === 5,
        'indent-6': indent === 6,
      }, className)}
      style={{ ...style, ...(indent !== undefined ? { paddingLeft: `${(indent * 3) + 1}rem` } : {}) }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </tr>
  );
};
