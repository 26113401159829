import React from 'react';
import { DragObjectWithType, DropTargetMonitor, useDrop } from 'react-dnd';
import { ConnectDropTarget } from 'react-dnd/lib/interfaces';
import { useDropArea } from './useDropArea';
import { TDragItem } from './DragAndDropContext';

export type TDropProps = {
  /** Event handler executed on dropping a drag area onto the droparea */
  onDrop?: (item: DragObjectWithType, monitor: DropTargetMonitor) => void;
  /** A unique name that is used to track the dropped items in this drop area */
  name: string;
  /** Identifier that indicates which drag areas it should accept a drop from */
  accept: string;
};
type AppendedDropAreaProps = {
  /** The ref that needs to be attached to the HTMLElement */
  element: ConnectDropTarget,
  /** The current list of items within this droparea */
  list: TDragItem[],
  /** Whether an item is currently hovering over the droparea */
  hovering: boolean;
  /** Whether the item can be dropped */
  droppable: boolean;
};

/**
 * A higher order component that transforms a component to a draggable component.
 * @param DropArea The component you want to turn into a drop area
 */
export const withDropability = <Props extends {} = {}>(DropArea: React.FC<Props & AppendedDropAreaProps>) => (props: Props & TDropProps) => {
  const { name, accept, onDrop } = props;

  const { list } = useDropArea(name);

  const [{ hovering, droppable }, dropElement] = useDrop({
    accept,
    collect: (monitor) => ({
      hovering: monitor.isOver(),
      droppable: monitor.canDrop(),
    }),
    drop: (item, monitor) => {
      if (onDrop) {
        onDrop(item, monitor);
      }
      return { dropArea: name };
    },
  });

  return (
    <DropArea
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      list={list}
      hovering={hovering}
      droppable={droppable}
      element={dropElement}
    />
  );
};
