import React, { PropsWithChildren, Ref } from 'react';
import classNames from 'classnames/bind';
import styles from './Button.module.css';
import { ButtonProps } from './ButtonProps';

const cx = classNames.bind(styles);

export const Button = React.forwardRef<HTMLButtonElement|HTMLAnchorElement, PropsWithChildren<ButtonProps>>(({
  href,
  alternative,
  block,
  className,
  disabled,
  download,
  error,
  file,
  info,
  link,
  loading,
  onClick,
  outline,
  quiet,
  round,
  small,
  success,
  style,
  target,
  to,
  title,
  transparent,
  warning,
  white,
  type = 'submit',
  onMouseOver,
  children,
}, ref) => {
  const isLink = (href || file || download || to);
  const classList = cx('base', {
    'is-link': link,
    'is-quiet': quiet,
    'is-button': !link,
    'is-round': round,
    'is-error': error,
    'is-info': info,
    'is-warning': warning,
    'is-alternative': alternative,
    'is-success': success,
    'is-white': white,
    'is-outline': outline,
    'is-loading': loading,
    'is-transparent': transparent,
    'is-disabled': disabled,
    'is-block': block,
    'is-small': small,
  }, className);

  return (
    <React.Fragment>
      {(isLink
        && (
        <a
          href={href || (to ? `#${to}` : '#')}
          download={(file || download)}
          target={target}
          style={style}
          ref={(ref as Ref<HTMLAnchorElement>)}
          title={title}
          aria-label={title}
          onClick={!disabled ? onClick : (e) => { e.preventDefault(); return false; }}
          onMouseOver={onMouseOver}
          className={classList}
        >
          {children}
        </a>
        )
      )}
      {(!isLink
        && (
        <button
          // eslint-disable-next-line react/button-has-type
          type={type}
          title={title}
          ref={(ref as Ref<HTMLButtonElement>)}
          aria-label={title}
          disabled={disabled}
          style={style}
          onClick={onClick}
          onMouseOver={onMouseOver}
          className={classList}
        >
          {children}
        </button>
        )
      )}
    </React.Fragment>
  );
});
