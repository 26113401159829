import React, { CSSProperties, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import styles from '../Table.module.css';
import { useTableProvider } from '../Context/useTableProvider';

const classes = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * Renders the body of the Table
 */
export const TBody = ({ className, style, children }: PropsWithChildren<Props>) => {
  const { header } = useTableProvider();
  return (
    <tbody className={classes(styles.body, { 'no-header': !header }, className)} style={style}>
      {children}
    </tbody>
  );
};
