import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { isPresent } from 'ts-is-present';
import { IFormContext, TPrimitive } from './Types';
import { FormContext } from './FormContext';

type TFieldConfig = {
  /** A unique identifier for this field */
  id?: string;
  /** A name to identify a certain field or group of fields */
  name?: string;
  /** Insert a controlled value. Must be combined with an onChange handler to track the changes */
  value?: string;
  /** Insert an uncontrolled value, will follow normal rules of form input */
  defaultValue?: string;
  /** Insert a controlled checked state. Must be combined with an onChange handler to track changes */
  checked?: boolean;
  /** Insert an uncontrolled checked state, which follows normal behavior of a form input, but cannot be changed dynamically after mount */
  defaultChecked?: boolean;
  /** Provide the validation rules applicable to this content field */
  rules?: string | Array<null | undefined | string>;
  /** Debounce the value a few milliseconds to ensure speedy updates on the component (recommended to keep low value) */
  debounce?: number;
};

/**
 * With this hook, you can connect an input to the Universal Form component. This can be either controlled or uncontrolled.
 *
 * If _controlled_, you are responsible for the correct value/checked state. Therefore, an onChange handler is mandatory!
 *
 * If _uncontrolled_, the value is set on mount, but after that, it is handled by the HTML engine. You cannot control the
 * value of the input from that point.
 *
 * **Do note:** The actual HTMLInputElement _might_ be controlled regardless of the Input _component_ to ensure synchronized
 * state with the Form element (in case the Form state wants to change the HTMLInputElement value).
 */
export function useFormField({
  id,
  name,
  value,
  defaultValue = '',
  checked,
  defaultChecked,
  rules,
}: TFieldConfig) {
  const isCheckable = (checked !== undefined || defaultChecked !== undefined);
  const isControlled = value !== undefined;

  const [controlledValue, setControlledValue] = useState<TPrimitive>(isControlled ? value : defaultValue);
  const [controlledChecked, setControlledChecked] = useState<boolean>(isCheckable ? !!defaultChecked : true);

  const {
    initialize,
    update,
    fields,
  } = useContext<null | IFormContext>(FormContext) || {};
  const field = !!id && fields?.find((item) => item.id === id);
  const fieldValue = field && field?.value;
  const isFormInitialized = !!field;

  // Initialize the input on the Form component
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!!id && !field && !!initialize && !initialized) {
      setInitialized(true);
      initialize(
        id,
        (name || id).replace('[]', ''),
        (controlledChecked ? controlledValue : null),
        (Array.isArray(rules) ? rules.filter(isPresent) : rules?.split('|')),
        !!name?.endsWith('[]'),
      );
    }
  }, [id, name, controlledChecked, controlledValue, rules, initialize, field, initialized]);

  // Provide a setter for setting the controlled value onChange
  const setControlledValueHandler = useCallback((newValue: TPrimitive) => {
    setControlledValue(newValue);
    if (!!id && !!update && !isControlled) {
      update(id, newValue);
    }
  }, [id, update, isControlled]);

  // When uncontrolled, update controlledValue when fieldValue changes
  useEffect(() => {
    if (!isControlled && !!fieldValue && fieldValue !== controlledValue) {
      setControlledValue(controlledChecked ? fieldValue : null);
    }
  }, [fieldValue, controlledValue, isControlled, controlledChecked]);

  // Provide a setter for setting the controlled checked state onChange
  const setControlledCheckedHandler = useCallback((newChecked: boolean) => {
    if (!!id && !!update) {
      update(id, newChecked ? controlledValue : null);
    }
    setControlledChecked(newChecked);
  }, [id, update, controlledValue]);

  // When uncontrolled, update controlledChecked when fieldValue changes
  useEffect(() => {
    const expectedChecked = fieldValue !== null;
    if (!isControlled && isCheckable && isFormInitialized && controlledChecked !== expectedChecked) {
      setControlledChecked(expectedChecked);
    }
  }, [fieldValue, isControlled, isCheckable, controlledChecked, isFormInitialized]);

  // When controlled checked state, update both controlledChecked & formValue on change
  useEffect(() => {
    if (checked !== undefined) {
      setControlledChecked(checked);
      const newValue = checked ? controlledValue : null;
      if (!!id && !!update && fieldValue !== newValue) {
        update(id, newValue);
      }
    }
  }, [checked, controlledValue, fieldValue, id, update]);

  // When controlled value state, update both controlledValue & formValue on change
  useEffect(() => {
    if (isControlled) {
      setControlledValue(value);
      if (fieldValue !== value && !!id) {
        update?.(id, value);
      }
    }
  }, [value, update, id, isControlled, fieldValue]);

  return {
    isFormConnected: !!id && fields !== undefined,
    value: controlledValue,
    setValue: setControlledValueHandler,
    checked: controlledChecked,
    setChecked: setControlledCheckedHandler,
    field,
  };
}
