import {
  MutableRefObject, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { isPresent } from 'ts-is-present';
import { FormContext } from './FormContext';
import { IFormConnectedElement, IFormContext } from './Types';

const INPUT_TYPES = {
  text: ['text', 'email', 'password', 'phone', 'color', 'date', 'datetime-local', 'month', 'number', 'search', 'tel', 'time', 'url', 'week', 'textarea'],
  none: ['none'],
};

/**
 * This hook connects an input field to the universal "Form" component.
 * It sets the event listeners when appropriate, calculates whether the element is active/inactive and more.
 * @param elementName {string}
 * @param element {MutableRefObject}
 * @param rules {string|array}
 * @returns Object
 *
 * @deprecated Use `useFormField` instead for improved API and better `value` and `defaultValue` handling
 */
export function useFormConnect(
  elementName: string,
  element: MutableRefObject<IFormConnectedElement>,
  rules?: string | Array<null | undefined | string>,
) {
  const context = useContext<null | IFormContext>(FormContext);

  const {
    initialize, update, fields, feedback, values, disabled,
  } = useMemo(() => (context || {
    initialize: () => null,
    update: () => null,
    fields: [],
    feedback: 'standard',
    values: {},
    disabled: false,
  }), [context]);

  const shouldFormConnect = (elementName !== '' && !!elementName && !!context);

  const isArray = useMemo(() => /\[]$/.test(elementName), [elementName]);
  const name = isArray ? elementName.slice(0, -2) : elementName;
  const [initialized, setInitialized] = useState(false);

  const identifier = !element.current.id
    ? name
    : (Number.isNaN(Number(element.current.id))
      ? element.current.id
      : `${name}_${element.current.id}`
    );

  const [id, setId] = useState(identifier);

  useEffect(() => {
    if (id !== identifier) {
      setId(identifier);
    }
  }, [identifier, id]);

  const field = useMemo(() => (fields && fields.find((item) => item.id === id)), [fields, id]);
  const formValue = shouldFormConnect && values?.[name];

  const rulesArray = useMemo(() => (
    typeof rules === 'string' ? rules.split('|') : (rules?.filter(isPresent) || [])
  ), [rules]);
  // Initialize element to form when value is set
  useEffect(() => {
    if (shouldFormConnect && !initialized) {
      const input = element.current;
      const isTextType = INPUT_TYPES.text.includes(input.type) || input.type === 'none';
      const isSelected = input.selected || input.checked;
      initialize(id, name, isTextType || isSelected ? input.value : null, rulesArray, isArray);
      setInitialized(true);
    }
  }, [id, shouldFormConnect, element, name, isArray, rulesArray, initialize, initialized]);

  // Create event handlers
  const handleChange = useCallback<((input: string | Event) => void)>((inputValue) => {
    const value = typeof inputValue === 'string' ? inputValue : element.current.value;
    if (shouldFormConnect && initialized) {
      const input = element.current;
      const isTextType = INPUT_TYPES.text.includes(input.type) || typeof inputValue === 'string';
      update(id, (isTextType || input.checked) ? value : null);
    }
  }, [id, element, update, shouldFormConnect, initialized]);

  // Set appropriate event listeners to handle form inputs
  useEffect(() => {
    if (shouldFormConnect && element) {
      const input = element.current;
      if (!input || !input.addEventListener) return;

      if (INPUT_TYPES.text.includes(input.type)) {
        input.addEventListener('keyup', handleChange);
      }
      if (!INPUT_TYPES.none.includes(input.type)) {
        input.addEventListener('change', handleChange);
      }

      return () => {
        if (INPUT_TYPES.text.includes(input.type)) {
          input.removeEventListener?.('keyup', handleChange);
        }
        if (!INPUT_TYPES.none.includes(input.type)) {
          input.removeEventListener?.('change', handleChange);
        }
      };
    }
  }, [shouldFormConnect, element, handleChange]);

  useEffect(() => {
    if (shouldFormConnect && element.current && field && formValue) {
      const input = element.current;
      const inputIsTextType = INPUT_TYPES.text.includes(element.current.type);
      if (inputIsTextType && input.value !== field.value) {
        input.value = field.value !== null ? field.value?.toString() : undefined;
      } else {
        input.checked = formValue && (formValue === input.value || (isArray && formValue.includes(input.value)));
        input.selected = formValue && (formValue === input.value || (isArray && formValue.includes(input.value)));
      }
    }
  }, [shouldFormConnect, element, formValue, isArray, field, name]);

  useEffect(() => {
    if (element.current && shouldFormConnect) {
      // eslint-disable-next-line no-param-reassign
      element.current.disabled = disabled;
    }
  }, [disabled, element, shouldFormConnect]);

  const exists = !!context;

  return useMemo(() => ({
    identifier: id,
    valid: feedback === 'full' ? field?.valid : undefined,
    messages: field?.messages || [],
    errors: feedback !== 'off' && (field?.messages || []),
    isLoading: disabled,
    isLocked: disabled || field?.locked,
    isArray,
    exists,
    triggerUpdate: handleChange,
    hasErrors: field?.messages && field?.messages.length,
    hasMessages: field?.messages && field?.messages.length,
    value: formValue,
    disabled,
  }), [exists, disabled, feedback, field?.locked, field?.messages, field?.valid, formValue, handleChange, id, isArray]);
}
