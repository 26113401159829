import React, { PropsWithChildren, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ModalSwitch, ErrorBoundary, TErrorComponent } from './Components';
import {
  TranslatorProvider,
  ITranslations,
  ConfigProvider,
  IConfig,
  TEnvironment,
  ModalProvider,
  ITheme,
} from './Providers';
import { ThemeProvider } from './Providers/Theme/ThemeProvider';

type TProps = {
  config?: IConfig;
  translations?: ITranslations;
  theme?: ITheme;
  /** Component that is shown when an error occurs. Will receive the error property and the content to show */
  onError?: TErrorComponent;
};

/**
 * The core initializes a set of base providers, enables sentry error tracking
 * and provides basic error handlers.
 */
export const Core = ({
  config,
  translations,
  theme,
  onError,
  children,
}: PropsWithChildren<TProps>) => {
  // Initializes error handling
  const dsn = config?.errors?.sentry?.dsn;
  const trackEnvironments = useMemo(() => config?.errors?.sentry?.track || ['production'], [config]);
  const currentEnvironment = config?.environment || process.env.NODE_ENV || 'development' as TEnvironment;
  if (trackEnvironments.indexOf(currentEnvironment) > -1 && dsn !== '') {
    const performanceMonitorRate = {
      production: 0.25,
      staging: 0,
      development: 1,
    }[currentEnvironment];

    Sentry.init({
      dsn,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: performanceMonitorRate,
      environment: config?.environment || process.env.NODE_ENV,
      release: config?.errors?.sentry?.release,
    });
  }

  return (
    <ErrorBoundary component={onError}>
      <ConfigProvider config={config}>
        <TranslatorProvider translations={translations}>
          <ThemeProvider theme={theme}>
            <ModalProvider>
              <ErrorBoundary component={onError}>
                {children}
                {config?.autoload?.modalSwitch && <ModalSwitch />}
              </ErrorBoundary>
            </ModalProvider>
          </ThemeProvider>
        </TranslatorProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
};
