import { useEffect, useState } from 'react';
import Color from 'color';
import kebabKeys from 'kebabcase-keys';

const colorRegex = /^(rgb|#|hsl|cymk)/i;

function toColor(colorString) {
  const c = colorRegex.test(colorString) ? colorString : colorString.split(',').map((val) => Number(val.trim()));
  return Color(c);
}

function toRGBString(colorObject) {
  return colorObject.rgb().array().map((val) => Math.round(val)).join(',');
}

export function useColors(colors) {
  const [cssProperties, setCssProperties] = useState({});

  useEffect(() => {
    const colorList = {};
    const darkTextColor = toColor(colors.darkTextColor || '60,60,80');
    const lightTextColor = toColor(colors.lightTextColor || '255,255,255');
    const kebabColors = kebabKeys(colors);
    Object.keys(kebabColors).forEach((colorName) => {
      if (kebabColors[colorName]) {
        const color = toColor(kebabColors[colorName]);
        const colorNameBase = colorName.replace('-auto', '');
        colorList[`--${colorNameBase}`] = toRGBString(color);
        colorList[`--${colorNameBase}-text`] = toRGBString(color.isLight() ? darkTextColor : lightTextColor);
        if (colorName.includes('-auto')) {
          colorList[`--${colorNameBase}-light`] = toRGBString(color.lighten(0.1));
          colorList[`--${colorNameBase}-lighter`] = toRGBString(color.lighten(0.2));
          colorList[`--${colorNameBase}-lightest`] = toRGBString(color.lighten(0.3));
          colorList[`--${colorNameBase}-dark`] = toRGBString(color.darken(0.1));
          colorList[`--${colorNameBase}-darker`] = toRGBString(color.darken(0.2));
          colorList[`--${colorNameBase}-darkest`] = toRGBString(color.darken(0.3));
          colorList[`--${colorNameBase}-negate`] = toRGBString(color.negate());
        }
      }
    });
    setCssProperties({
      '--dark-text-color': toRGBString(darkTextColor),
      '--light-text-color': toRGBString(lightTextColor),
      ...colorList,
    });
  }, [colors]);

  return cssProperties;
}
