import React, {
  PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import dotProp from 'dot-prop';
import merge from 'deepmerge';
import { TranslatorContext, ITranslatorContext } from './TranslatorContext';
import defaultTranslations from '../../Translations';
import { ITranslations } from './TranslationsInterface';
import { useConfig } from '../Config';
import { DateFormatter } from '../../Utils';

type TProps = {
  translations?: ITranslations;
};

/**
 * The translations provider makes the translations file accessible through a `useTranslator` hook.
 */
export const TranslatorProvider = ({ translations: providedTranslations, children }: PropsWithChildren<TProps>) => {
  const translations = useMemo(() => merge(defaultTranslations, providedTranslations || {}), [providedTranslations]);

  const [{ translator, isDevelopment }] = useConfig();

  const [settings, setSettings] = useState({
    type: translator?.type || 'education',
    language: translator?.language || 'nl',
  });

  const missingTranslations = useRef<string[]>([]);
  const translate = useCallback<ITranslatorContext['translate']>((identifier, data = {}, fallback) => {
    const prefix = `${settings.type}.${settings.language}`;
    const translated = (
      dotProp.get(translations, `${prefix}.${identifier}`)
      || dotProp.get(translations, `${settings.type}.${identifier}`)
      || dotProp.get(translations, identifier)
      || fallback
      || identifier
    );
    if (isDevelopment && !fallback) {
      Object.keys(translations).forEach((translationType) => {
        Object.keys(translations[translationType]).forEach((translationLang) => {
          const languageString = `${translationType}.${translationLang}.${identifier}`;
          if (!dotProp.get(translations, languageString) && !missingTranslations.current.includes(languageString)) {
            missingTranslations.current.push(languageString);
            // eslint-disable-next-line
            console.warn(`%c[TRANSLATION] Please add appropriate translations for '${translationLang}' for '${identifier}':`, 'font-weight: bold;');
          }
        });
      });
    }
    return typeof translated === 'function' ? translated(data) : translated;
  }, [translations, settings, isDevelopment]);

  useEffect(() => {
    DateFormatter.setLocale(settings.language, translate);
  }, [settings.language, translate]);

  const setLanguage = useCallback((type, language) => {
    setSettings((prevSettings) => ({ ...prevSettings, ...{ type, language } }));
  }, []);

  const state = useMemo<ITranslatorContext>(() => ({
    translate,
    setLanguage,
  }), [translate, setLanguage]);

  return (
    <TranslatorContext.Provider value={state}>
      {children}
    </TranslatorContext.Provider>
  );
};
