import { Dispatch } from 'react';

export enum DataProviderActions {
  SORT_ON = 'SORT_ON',
  SEARCH_ON = 'SEARCH_ON',
  SET_FILTER = 'SET_FILTER',
  TOGGLE_FILTER = 'TOGGLE_FILTER',
  REMOVE_FILTER = 'REMOVE_FILTER',
  RESET = 'RESET',
}

export interface SET_FILTER_ACTION<T> {
  type: DataProviderActions.SET_FILTER;
  payload: {
    [name: string]: Partial<T> | ((item: T) => boolean) | boolean;
  }
}
export interface REMOVE_FILTER_ACTION {
  type: DataProviderActions.REMOVE_FILTER;
  payload: string;
}

interface TOGGLE_FILTER_ACTION<T> {
  type: DataProviderActions.TOGGLE_FILTER;
  payload: {
    [name: string]: Partial<T> | ((item: T) => boolean) | boolean;
  }
}

interface SEARCH_ON_ACTION<T> {
  type: DataProviderActions.SEARCH_ON;
  payload: Partial<T>;
  meta?: {
    flags?: string
  }
}
interface SORT_ON_ACTION<T> {
  type: DataProviderActions.SORT_ON;
  payload: {
    id: string;
    method: keyof T | ((a: T, b: T) => -1 | 0 | 1);
    direction?: 'ASC' | 'DESC';
  }
}

export type TActions<T> =
  SORT_ON_ACTION<T>
  | SET_FILTER_ACTION<T>
  | TOGGLE_FILTER_ACTION<T>
  | REMOVE_FILTER_ACTION
  | SEARCH_ON_ACTION<T>;

export type DataState<T> = {
  filters?: {
    [name: string]: (item: T) => boolean;
  };
  sort?: {
    id: string;
    method: (a: T, B: T) => -1 | 0 | 1;
    direction?: 'ASC' | 'DESC'
  }
};

export interface IDataItem {
  [key: string]: any;
}

export type TDataDispatcher<T extends object = any> = Dispatch<TActions<T>>;

export type TDataContext<T extends object = any> = readonly [T[], {
  isLoading: boolean,
  sort: DataState<T>['sort'],
  filters: DataState<T>['filters'],
  dispatch: TDataDispatcher<T>,
}];

export type TSelectionContext = {
  selection: string[],
  handleToggleSelect: (ids: string[]) => void;
  handleToggleSelectAll: (ids?: string[]) => void;
};
